import {
  Controller,
  type FieldPath,
  type RegisterOptions,
  get,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { faInfoCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  EMAIL_VALIDATION,
  commonFields,
  useChangelingStore,
  validationErrors,
} from '@trustyou/shared';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  ComposableDrawerWithStickyFooter,
  FormControlLabel,
  Stack,
  StyledFontAwesomeIcon,
  TextField,
  Tooltip,
  Typography,
  snackbar,
  styled,
} from '@trustyou/ui';

import { DRAWER_WIDTH } from '../../../constants';
import { useLeaveDialog, useReview } from '../../../hooks';
import type { ResponseFormSchema, ResponseTranslationOption } from '../../../types';
import { CustomTextField } from '../../styled-input';

const StyledLabel = styled(Typography)(() => ({
  width: '120px',
}));

export const EmailDetailsDrawer = ({
  isOpen,
  onClose,
  radioValue,
}: {
  isOpen?: boolean;
  onClose: () => void;
  radioValue?: ResponseTranslationOption;
}) => {
  const intl = useIntl();
  const { isChangeling } = useChangelingStore();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const isPublic = reviewRoot?.survey?.privacy_level === 'public';
  const {
    register,
    getValues,
    setValue,
    resetField,
    getFieldState,
    control,
    formState: { errors, dirtyFields },
  } = useFormContext<ResponseFormSchema>();
  const sendDifferentResponse = useWatch({ name: 'emailDetails.sendDifferentResponse' });
  const { canContinue, renderLeaveDialog } = useLeaveDialog({
    showDialog: !!dirtyFields.emailDetails,
  });

  const onSave = () => {
    snackbar.success(intl.formatMessage(commonFields.changesSaved));
    resetField('emailDetails', { defaultValue: getValues('emailDetails') });
    onClose();
  };

  const onCancel = async () => {
    if (await canContinue()) {
      resetField('emailDetails');
      onClose();
    }
  };

  const mapRadioValueToResponse = (radioValue?: ResponseTranslationOption) => {
    if (radioValue === 'original' && !!getValues('response')) {
      return getValues('response');
    } else if (radioValue === 'translated' && !!getValues('translatedResponse')) {
      return getValues('translatedResponse');
    }
    return getValues('response');
  };

  const renderRequiredInput = (
    registerId: FieldPath<keyof ResponseFormSchema['emailDetails']>,
    label: string,
    registerOptions?: RegisterOptions<ResponseFormSchema, never>
  ) => (
    <Stack direction="row" gap={2}>
      <StyledLabel>{label} *</StyledLabel>
      <TextField
        {...register(registerId, { required: true, ...registerOptions })}
        fullWidth
        size="small"
        error={!!get(errors, registerId)}
        helperText={get(errors, registerId)?.message ?? ' '}
      />
    </Stack>
  );

  const renderMultiEmailField = (
    registerId: FieldPath<keyof ResponseFormSchema['emailDetails']>,
    label: string,
    hint: string
  ) => (
    <Controller
      name={registerId}
      control={control}
      rules={{ pattern: EMAIL_VALIDATION }}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Stack direction="row" gap={2}>
          <StyledLabel>
            {label}
            <Tooltip placement="top" arrow title={hint}>
              <StyledFontAwesomeIcon
                icon={faInfoCircle}
                sx={{ marginLeft: 1, color: 'text.secondary' }}
              />
            </Tooltip>
          </StyledLabel>
          <Autocomplete
            {...field}
            autoSelect
            fullWidth
            freeSolo
            multiple
            size="small"
            options={[]}
            limitTags={1}
            value={field.value ?? []}
            onChange={(_, value) => {
              field.onChange(value);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip size="small" label={option} color="info" {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                ref={ref}
                {...params}
                placeholder={intl.formatMessage({
                  id: 'inbox.response.email-details.email-placeholder',
                  defaultMessage: 'Add emails',
                })}
                error={!!error}
                helperText={error ? intl.formatMessage(validationErrors.invalidEmail) : ' '}
              />
            )}
          />
        </Stack>
      )}
    />
  );

  const renderCheckbox = (
    registerId: FieldPath<keyof ResponseFormSchema['emailDetails']>,
    label: string,
    onClick = (event: React.MouseEvent<HTMLButtonElement>) => {}
  ) => (
    <Controller
      name={registerId}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox {...field} checked={field.value ?? false} onClick={onClick} />}
          label={label}
        />
      )}
    />
  );

  const prefillEmailResponse = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!getValues('emailDetails.differentResponse')) {
      const response = mapRadioValueToResponse(radioValue);
      setValue('emailDetails.differentResponse', response);
    }
  };

  return (
    <>
      <ComposableDrawerWithStickyFooter
        anchor="right"
        open={isOpen}
        onClose={onCancel}
        PaperProps={{
          sx: {
            width: DRAWER_WIDTH,
            paddingTop: isChangeling ? 8 : 0,
          },
        }}
      >
        <ComposableDrawerWithStickyFooter.Header
          title={intl.formatMessage({
            id: 'inbox.response.email-details.title',
            defaultMessage: 'Email details',
          })}
          sx={{ paddingInline: 3 }}
        />
        <ComposableDrawerWithStickyFooter.Content sx={{ paddingInline: 3 }}>
          <Stack gap={4}>
            <Stack gap={2}>
              {renderRequiredInput(
                'emailDetails.subject' as keyof ResponseFormSchema['emailDetails'],
                intl.formatMessage({
                  id: 'inbox.response.email-details.subject',
                  defaultMessage: 'Subject',
                })
              )}
              {renderRequiredInput(
                'emailDetails.from' as keyof ResponseFormSchema['emailDetails'],
                intl.formatMessage({
                  id: 'inbox.response.email-details.from',
                  defaultMessage: 'From',
                }),
                { pattern: EMAIL_VALIDATION }
              )}
              {renderMultiEmailField(
                'emailDetails.cc' as keyof ResponseFormSchema['emailDetails'],
                intl.formatMessage({
                  id: 'inbox.response.email-details.cc',
                  defaultMessage: 'CC',
                }),
                intl.formatMessage({
                  id: 'inbox.response.email-details.cc-hint',
                  defaultMessage: 'Send a copy to this email',
                })
              )}
              {renderMultiEmailField(
                'emailDetails.bcc' as keyof ResponseFormSchema['emailDetails'],
                intl.formatMessage({
                  id: 'inbox.response.email-details.bcc',
                  defaultMessage: 'BCC',
                }),
                intl.formatMessage({
                  id: 'inbox.response.email-details.bcc-hint',
                  defaultMessage: 'Send a secret copy to this email',
                })
              )}
            </Stack>
            <Stack>
              {renderCheckbox(
                'emailDetails.attachReview' as keyof ResponseFormSchema['emailDetails'],
                intl.formatMessage({
                  id: 'inbox.response.email-details.attach-original-review',
                  defaultMessage: 'Attach original review text to the email',
                })
              )}
              {isPublic &&
                renderCheckbox(
                  'emailDetails.sendDifferentResponse' as keyof ResponseFormSchema['emailDetails'],
                  intl.formatMessage({
                    id: 'inbox.response.email-details.send-different-response',
                    defaultMessage: 'Send a different response directly to the guest',
                  }),
                  prefillEmailResponse
                )}
            </Stack>
            {sendDifferentResponse && (
              <CustomTextField
                id="emailDetails.differentResponse"
                nonFloatingLabel={
                  <FormattedMessage
                    id="inbox.response.email-details.different-response.title"
                    defaultMessage="Email response"
                  />
                }
                minRows={3}
                maxRows={20}
                fullWidth
                multiline
              />
            )}
          </Stack>
        </ComposableDrawerWithStickyFooter.Content>
        <ComposableDrawerWithStickyFooter.Footer
          primaryButton={
            <Button
              onClick={onSave}
              type="button"
              variant="contained"
              disabled={getFieldState('emailDetails')?.invalid}
            >
              <FormattedMessage id="inbox.action.save" defaultMessage="Save" />
            </Button>
          }
          secondaryButton={
            <Button onClick={onCancel} type="button" color="inherit">
              <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
            </Button>
          }
          sx={{ boxShadow: 5 }}
        />
      </ComposableDrawerWithStickyFooter>
      {renderLeaveDialog({
        title: intl.formatMessage({
          id: 'inbox.response.email-details.discard-title',
          defaultMessage: 'Leave editor and discard changes?',
        }),
        content: intl.formatMessage({
          id: 'inbox.response.email-details.discard-content',
          defaultMessage: 'Your changes will be lost if you leave the email editor',
        }),
      })}
    </>
  );
};
