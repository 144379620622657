import { FormattedMessage, useIntl } from 'react-intl';

import { green, orange, red } from '@mui/material/colors';
import { Tooltip, Typography } from '@trustyou/ui';

import type { SemaMatchesSchema } from '../../client';
import { useSemanticCategoryItems } from '../../hooks';

type HighlightTextProps = {
  text?: string | null;
  sema_matches?: SemaMatchesSchema | null;
};

const sentimentColors = {
  n: {
    bg: red[50],
    text: red[900],
  },
  p: {
    bg: green[50],
    text: green[900],
  },
  o: {
    bg: orange[50],
    text: orange[900],
  },
};

export const HighlightText = ({ text, sema_matches }: HighlightTextProps) => {
  const { getCategoriesForText } = useSemanticCategoryItems();
  const intl = useIntl();

  const uniqueOpinions = Array.from(
    new Map(
      sema_matches?.opinions?.map((opinion) => [
        `${opinion.char_start}-${opinion.char_end}`,
        opinion,
      ])
    ).values()
  ).sort((a, b) => a.char_start - b.char_start);

  const renderText = () => {
    const elements = [];
    let lastIndex = 0;

    if (text) {
      uniqueOpinions.forEach((opinion, index) => {
        if (opinion.char_start > lastIndex) {
          elements.push(
            <span key={`text-${index}`}>{text.substring(lastIndex, opinion.char_start)}</span>
          );
        }

        const category = getCategoriesForText(opinion.text);

        if (category) {
          const color = sentimentColors[opinion.sentiment] || 'inherit';

          elements.push(
            <Tooltip
              key={`tooltip-${index}`}
              placement="top"
              arrow
              title={`${intl.formatMessage({
                id: 'inbox.common.related-to',
                defaultMessage: 'Related to',
              })}: ${category}`}
            >
              <span
                style={{
                  backgroundColor: color.bg,
                  display: 'inline-block',
                  padding: '2px 4px',
                  borderRadius: '2px',
                  color: color.text,
                }}
              >
                {text.substring(opinion.char_start, opinion.char_end)}
              </span>
            </Tooltip>
          );
        } else {
          elements.push(
            <span key={`text-${index}-nocategory`}>
              {text.substring(opinion.char_start, opinion.char_end)}
            </span>
          );
        }

        lastIndex = opinion.char_end;
      });

      if (lastIndex < text.length) {
        elements.push(<span key="text-end">{text.substring(lastIndex)}</span>);
      }
    }

    return elements;
  };

  return (
    <Typography
      variant="body2"
      sx={{
        overflowY: 'auto',
        whiteSpace: 'pre-wrap',
        ...(!text && { color: (theme) => theme.palette.text.secondary }),
      }}
    >
      {text ? renderText() : <FormattedMessage id="inbox.no-summary" defaultMessage="No summary" />}
    </Typography>
  );
};
