import type { BasicEntity } from '@trustyou/shared';
import type { StateCreator } from 'zustand';

import type { ReviewSchema } from '../../client';

// import type { ReviewRoot } from '../../types/reviews';

export interface ReviewSliceState {
  reviewsList: ReviewSchema[];
  updateReviewsList: (reviewsList: ReviewSchema[]) => void;

  // reviewRoot: ReviewRoot | undefined;
  // updateReviewRoot: (review: ReviewRoot | undefined) => void;

  entityData: BasicEntity | undefined;
  updateEntityData: (entityData: BasicEntity | undefined) => void;

  restrictedScope: string[];
  updateRestrictedScope: (entityIds: string[]) => void;
}

export const createReviewSlice: StateCreator<ReviewSliceState, [], []> = (set) => ({
  reviewsList: [],
  updateReviewsList: (reviewsList) => set(() => ({ reviewsList })),

  // reviewRoot: undefined,
  // updateReviewRoot: (reviewRoot) => set(() => ({ reviewRoot })),

  entityData: undefined,
  updateEntityData: (entityData) => set(() => ({ entityData })),

  restrictedScope: [],
  updateRestrictedScope: (entityIds) => set(() => ({ restrictedScope: entityIds })),
});
