import type { PropsWithChildren, ReactNode } from 'react';

import type { SxProps, Theme } from '@mui/material';
import { Box, Typography } from '@trustyou/ui';

type SettingSectionProps = PropsWithChildren & {
  title: ReactNode;
  description: ReactNode;
  sx?: SxProps<Theme>;
};

export function SettingsSection({ title, description, sx, children }: SettingSectionProps) {
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Typography fontWeight={500} variant="subtitle1">
        {title}
      </Typography>
      <Typography color="text.secondary">{description}</Typography>
      {children}
    </Box>
  );
}
