import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  type SentimentEnum,
  SentimentIcon,
  useReview,
  useTranslatePermission,
} from '@trustyou/inbox';
import { isDefined, stringToShortDate, useLanguageStore } from '@trustyou/shared';
import { Box, Button, Typography } from '@trustyou/ui';

import styles from './styles';

type ReviewCardProps = {
  id: string;
  text: string;
  entity: string;
  date: string;
  title?: string;
  sentiments?: SentimentEnum[];
  score?: number | null;
  source?: string;
};
export const ReviewCard = ({
  id,
  date,
  score,
  title,
  text,
  entity,
  source,
  sentiments = [],
}: ReviewCardProps) => {
  const isTranslateAllowed = useTranslatePermission();
  const [shouldFetchTranslation, setShouldFetchTranslation] = useState(false);
  const intl = useIntl();
  const { locale } = useLanguageStore();
  const review = useReview({ reviewId: id, shouldFetchTranslation });

  const translatedText = review.data?.review.translations?.[locale]?.text || text;
  const translatedTitle = review.data?.review.translations?.[locale]?.title || title;

  const secondaryTexts = [stringToShortDate(date), entity];
  const tertiaryTexts: string[] = [];
  if (source) tertiaryTexts.push(source);
  if (isDefined(score)) {
    tertiaryTexts.push(
      intl.formatMessage(
        {
          id: 'analytics.reviews-modal.review-score-value',
          defaultMessage: '{score} review score',
        },
        { score: score.toFixed(2) }
      )
    );
  }

  const onTranslate = () => {
    setShouldFetchTranslation((state) => !state);
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.avatarContainer}>
        {sentiments.includes('positive') && <SentimentIcon type="positive" />}
        {sentiments.includes('neutral') && <SentimentIcon type="neutral" />}
        {sentiments.includes('negative') && <SentimentIcon type="negative" />}
      </Box>
      <Box sx={styles.body}>
        {translatedTitle && <Typography variant="body2">{translatedTitle}</Typography>}
        <Typography variant="body2" sx={styles.reviewText}>
          {translatedText}
        </Typography>
        {isTranslateAllowed && (
          <Button onClick={onTranslate} type="button" sx={styles.translateButton}>
            {shouldFetchTranslation ? (
              <FormattedMessage
                id="analytics.reviews-modal.show-original"
                defaultMessage="Show original"
              />
            ) : (
              <FormattedMessage id="analytics.reviews-modal.translate" defaultMessage="Translate" />
            )}
          </Button>
        )}
        <Box sx={styles.secondaryText}>
          <Typography variant="body2">{secondaryTexts.join(' | ')}</Typography>
          <Typography variant="body2">{tertiaryTexts.join(' | ')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
