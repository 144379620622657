import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { MenuItem, Stack, Typography } from '@trustyou/ui';

import { useContents } from '../../../../service/hooks/use-questions';
import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

export function EditorThankYouContent() {
  const { register, setValue, watch } = useFormContext();
  const { data: thankyouMessages = [] } = useContents({ types: ['thankyou_message'] });
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const thankyouMessageId =
    watch('surveyQuestionnaireEditorThankyouMessage')?.id || thankyouMessages[0]?.id || '';

  useEffect(() => {
    register('surveyQuestionnaireEditorThankyouMessage');
    setValue('surveyQuestionnaireEditorThankyouMessage', thankyouMessages[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thankyouMessages]);

  return (
    <Stack spacing={4}>
      <Stack>
        <Typography variant="h6">
          <FormattedMessage
            id="survey.survey-editor.thank-you-message.title"
            defaultMessage="Thank you page"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.thank-you-message.description"
            defaultMessage="Shown after respondents submit their review. To create a custom one, visit the Content library."
          />
        </Typography>
      </Stack>
      <CustomTextFieldWithSeparatedLabel
        select
        size="small"
        value={thankyouMessageId}
        onChange={(e) => {
          const selectedMessage = thankyouMessages.find((item) => item.id === e.target.value);
          setValue('surveyQuestionnaireEditorThankyouMessage', selectedMessage);
        }}
      >
        {thankyouMessages.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title?.[defaultSurveyLanguage] ??
              item.title?.[item.default_language ?? 'en'] ??
              'Thank you text'}
          </MenuItem>
        ))}
      </CustomTextFieldWithSeparatedLabel>
    </Stack>
  );
}
