import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button } from '@trustyou/ui';

import { useStore } from '../../store/store';

export function NewSurveyQuestionnaireButton() {
  const navigate = useNavigate();
  const setIsSurveyQuestionnaireEditorOpen = useStore.use.setIsSurveyQuestionnaireEditorOpen();

  const navigateToSurveyQuestionnaireEditor = () => {
    setIsSurveyQuestionnaireEditorOpen(true);
    navigate('survey/questionnaire-editor');
  };

  return (
    <Button
      variant="contained"
      startIcon={<FontAwesomeIcon icon={faPlus} />}
      onClick={navigateToSurveyQuestionnaireEditor}
    >
      <FormattedMessage id="survey.common.new-questionnaire" defaultMessage="New Questionnaire" />
    </Button>
  );
}
