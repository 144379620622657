/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, type DropResult, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import type {
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { DEFAULT_PAGE_SIZE, getPaginationRequest } from '@trustyou/shared';
import { Button, StyledFontAwesomeIcon as Icon, Stack } from '@trustyou/ui';

import { ContentHeader } from './components/content-header';
import { ContentItem } from './components/content-item';
import { EmptyContentPlaceholder } from './components/empty-content-placeholder';
import { SurveyContentList } from './components/survey-contents-list';
import { SurveySectionList } from './components/survey-section-list';

import { useContents, useQuestions } from '../../../../service/hooks/use-questions';
import type {
  Content_Output,
  QuestionToSectionProps,
  SurveyEditorContentProps,
  SurveyQuestionnaireEditorContentOutput,
} from '../../../../types/survey';
import { filterChosenContents } from '../../../../utils/survey-questionnaire-editor';

export function EditorSurveyContent() {
  const { control, setValue, watch, getValues, trigger } = useFormContext();
  const { move, append, remove } = useFieldArray({
    control,
    name: 'surveyContent',
  });
  //Pagination
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  //API to get list of question contents
  const { data: contents = [], refetch: refetchContents } = useQuestions(
    getPaginationRequest(paginationModel)
  );
  const { data: sections = [], refetch: refetchSections } = useContents({
    types: ['section'],
    limit: getPaginationRequest(paginationModel).limit,
    offset: getPaginationRequest(paginationModel).offset,
  });
  const [fetchedQuestions, setFetchedQuestions] = useState<Content_Output[]>(contents);
  const [fetchedSections, setFetchedSections] = useState<Content_Output[]>(
    sections as Content_Output[]
  );
  //Content List Dialog
  const [openContentDialog, setOpenContentDialog] = useState(false);
  const [contentRowSelectModel, setContentRowSelectModel] = useState<GridRowSelectionModel>([]);
  const [questionToSection, setQuestionToSection] = useState<QuestionToSectionProps | null>(null);
  //Section List Dialog
  const [openSectionDialog, setOpenSectionDialog] = useState(false);
  const [sectionRowSelectModel, setSectionRowSelectModel] = useState<GridRowParams>();
  const [updateSectionIndex, setUpdateSectionIndex] = useState<number | null>(null);

  //User chosen list of contents
  const surveyContent: SurveyEditorContentProps[] = watch('surveyContent');

  useEffect(() => {
    setFetchedQuestions(contents);
    setFetchedSections(sections as Content_Output[]);
  }, [contents, sections]);

  useEffect(() => {
    refetchContents();
    refetchSections();
  }, [paginationModel]);

  //Filtering the chosen contents from the list of all contents
  useEffect(() => {
    const filteredContents = filterChosenContents({
      allItems: contents,
      chosenItems: surveyContent,
    });
    const filteredSections = filterChosenContents({
      allItems: sections,
      chosenItems: surveyContent,
    });
    setFetchedQuestions(filteredContents as Content_Output[]);
    setFetchedSections(filteredSections as Content_Output[]);
  }, [contents, sections, surveyContent]);

  const addQuestion = () => {
    setOpenContentDialog(false);
    const chosenContentData = contents.filter((item) => contentRowSelectModel.includes(item.id));

    if (questionToSection) {
      addQuestionToSection({
        sectionId: questionToSection.sectionId,
        questions: chosenContentData,
      });
      setQuestionToSection(null);
    } else {
      append(chosenContentData);
    }
    setContentRowSelectModel([]);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const addQuestionToSection = ({ sectionId, questions = [] }: QuestionToSectionProps) => {
    const updatedContent = surveyContent.map((item: SurveyEditorContentProps) => {
      const currentQuestions = item.questions || [];
      if (item.id === sectionId) {
        return {
          ...item,
          questions: [...currentQuestions, ...questions],
        };
      }
      return item;
    });

    setValue('surveyContent', updatedContent);
  };

  const addOrUpdateSection = () => {
    if (updateSectionIndex !== null) {
      const newData = [...surveyContent];
      const originalItem = surveyContent[updateSectionIndex];
      const originalQuestions = originalItem.questions;

      const updatedContent = {
        ...sectionRowSelectModel?.row,
        questions: originalQuestions,
      };
      newData[updateSectionIndex] = updatedContent;

      setValue('surveyContent', newData);
      setUpdateSectionIndex(null);
      setOpenSectionDialog(false);
    } else {
      setOpenSectionDialog(false);
      if (sectionRowSelectModel?.row) {
        append(sectionRowSelectModel.row);
      }
    }
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const deleteQuestionInSection = ({ sectionId, questionId }: QuestionToSectionProps) => {
    const updatedPageContent = surveyContent.map((item: SurveyEditorContentProps) => {
      if (item.id === sectionId) {
        const updatedQuestions = item.questions.filter(
          (q: SurveyQuestionnaireEditorContentOutput) => q.id !== questionId
        );
        return {
          ...item,
          questions: updatedQuestions,
        };
      }
      return item;
    });

    setValue('surveyContent', updatedPageContent);
  };

  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination, type } = result;

    if (!destination) {
      return;
    }
    const isSectionQuestion = type === 'section-questions';
    if (isSectionQuestion) {
      const sectionIndex = surveyContent.findIndex(
        (section) => section.id.toString() === source.droppableId
      );
      const questions = getValues(`surveyContent[${sectionIndex}].questions`);

      const [movedQuestion] = questions.splice(source.index, 1);
      questions.splice(destination.index, 0, movedQuestion);

      setValue(`surveyContent[${sectionIndex}].questions`, questions);
    } else {
      move(source.index, destination.index);
    }
    trigger('surveyContent');
  };

  return (
    <Stack>
      <ContentHeader
        openContentDialog={() => setOpenContentDialog(true)}
        openSectionDialog={() => setOpenSectionDialog(true)}
      />
      {!surveyContent ||
        (surveyContent.length < 1 && (
          <EmptyContentPlaceholder actionHandler={() => setOpenContentDialog(true)} />
        ))}
      <SurveyContentList
        data={fetchedQuestions}
        open={openContentDialog}
        rowSelectionModel={contentRowSelectModel}
        onRowSelectionModelChange={setContentRowSelectModel}
        onCancel={() => setOpenContentDialog(false)}
        onSubmit={addQuestion}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
      <SurveySectionList
        data={fetchedSections}
        open={openSectionDialog}
        onRowClick={setSectionRowSelectModel}
        onCancel={() => setOpenSectionDialog(false)}
        onSubmit={addOrUpdateSection}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable-survey-content">
          {(provided) => (
            <Stack {...provided.droppableProps} ref={provided.innerRef} spacing={2} mt={3}>
              {surveyContent &&
                surveyContent?.map((content: SurveyEditorContentProps, index) => (
                  <Draggable key={content.id} draggableId={content.id.toString()} index={index}>
                    {(provided) => (
                      <Stack
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        spacing={2}
                      >
                        <ContentItem
                          key={content.id}
                          content={content}
                          type={content.type}
                          onDelete={() => remove(index)}
                          onEditSection={() => {
                            setOpenSectionDialog(true);
                            setUpdateSectionIndex(index);
                          }}
                        >
                          {content.type && content.type === 'section' && (
                            <>
                              <Droppable
                                droppableId={content.id.toString()}
                                type="section-questions"
                              >
                                {(provided) => (
                                  <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {content.questions?.map(
                                      (
                                        question: SurveyQuestionnaireEditorContentOutput,
                                        questionIndex: number
                                      ) => (
                                        <Draggable
                                          key={question.id}
                                          draggableId={question.id.toString()}
                                          index={questionIndex}
                                        >
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <ContentItem
                                                content={question}
                                                isQuestionInsideSection
                                                onDelete={() =>
                                                  deleteQuestionInSection({
                                                    sectionId: content.id,
                                                    questionId: question.id,
                                                  })
                                                }
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                  </div>
                                )}
                              </Droppable>
                              <Stack sx={{ alignItems: 'center', marginBlock: 2 }}>
                                <Button
                                  variant="text"
                                  startIcon={<Icon icon={faPlus} />}
                                  onClick={() => {
                                    setQuestionToSection({ sectionId: content.id });
                                    setOpenContentDialog(true);
                                  }}
                                >
                                  <FormattedMessage
                                    id="survey.common.content"
                                    defaultMessage="Content"
                                  />
                                </Button>
                              </Stack>
                            </>
                          )}
                        </ContentItem>
                      </Stack>
                    )}
                  </Draggable>
                ))}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </Stack>
  );
}
