import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useLanguageStore } from '@trustyou/shared';
import { List, ListItem, Typography } from '@trustyou/ui';
import dayjs from 'dayjs';

import { BooleanQuestion, MultipleChoiceQuestion, TextQuestion } from './question-elements';

import type { SurveyAnswer } from '../../client';
import { useLanguage, useReview } from '../../hooks';
import { NumericScaleOptions } from '../../types';

const DEFAULT_MAX_SCALE = 10;
const DATE_INPUT_FORMAT = 'YYYY-MM-DD';
const DATE_OUTPUT_FORMAT = 'DD MMM, YYYY';
// const MONTH_AND_YEAR_OF_TRAVEL_DATE_FORMAT = 'MMM, YYYY';

export const Survey = () => {
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const answers = reviewRoot?.survey?.answers ?? [];
  const surveyDefaultLanguage = reviewRoot?.survey?.default_language ?? 'en';
  const { locale } = useLanguageStore();
  const { getFormattedLanguageCode } = useLanguage();
  const userLanguage = getFormattedLanguageCode(locale);

  const getMaxScale = (scale?: NumericScaleOptions): number => {
    if (scale === NumericScaleOptions.ONE_TO_FIVE) return 5;
    if (scale === NumericScaleOptions.ONE_TO_TEN) return 10;
    return DEFAULT_MAX_SCALE;
  };

  const getTranslatedText = (textObj: Record<string, string>) => {
    return textObj[userLanguage] ?? textObj[surveyDefaultLanguage] ?? Object.values(textObj).at(0);
  };

  if (answers.length === 0) {
    return null;
  }

  return (
    <List data-testid="survey-block">
      {answers.map(({ question_id, internal_name, answer, primitive }) => {
        const questionTitle = getTranslatedText(internal_name);
        const getAnsweredQuestion = () => {
          if (answer.not_applicable) {
            return (
              <TextQuestion
                name={questionTitle}
                text={
                  <FormattedMessage
                    id="inbox.survey.answer.notApplicable"
                    defaultMessage="Not applicable"
                  />
                }
              />
            );
          }
          if (Object.keys(answer.value).length === 0) {
            return;
          }
          switch (true) {
            // case answer.type === 'traveller_type':
            //   return <TravellerTypeQuestion key={`${id}_${index}`} name={name} text={value.radio} />;
            // case answer.type === 'year_month_of_travel':
            //   return (
            //     <TextQuestion
            //       name={name}
            //       text={dayjs(value.date, DATE_INPUT_FORMAT).format(
            //         MONTH_AND_YEAR_OF_TRAVEL_DATE_FORMAT
            //       )}
            //       showText={!!value.date}
            //     />
            //   );
            // case answer.type === 'traveller_nationality':
            //   return (
            //     <MultipleChoiceQuestion
            //       name={intl.formatMessage({
            //         id: 'inbox.survey.question.country',
            //         defaultMessage: 'Country',
            //       })}
            //       answers={value.dropdown}
            //     />
            //   );
            // case answer.type === 'checkbox':
            //   return <MultipleChoiceQuestion key={id} name={name} answers={value.checkbox} />;
            case primitive.type === 'boolean':
              return <BooleanQuestion name={questionTitle} value={answer.value.bool_val} />;
            case primitive.type === 'scale':
              return (
                <TextQuestion
                  name={questionTitle}
                  text={
                    answer.not_applicable ? (
                      <FormattedMessage
                        id="inbox.survey.answer.notApplicable"
                        defaultMessage="Not applicable"
                      />
                    ) : (
                      `${Number(answer.value.int_val)} / 100`
                    )
                  }
                />
              );
            case primitive.type === 'nps':
              return (
                <TextQuestion
                  name={questionTitle}
                  text={`${Number(answer.value.int_val)} / ${getMaxScale(NumericScaleOptions.ONE_TO_TEN)}`}
                />
              );
            case primitive.type === 'select':
              return (
                <MultipleChoiceQuestion
                  name={questionTitle}
                  answers={answer.value.array_val?.map((answer) => answer.label) ?? []}
                />
              );
            case answer.type === 'date':
              return (
                <TextQuestion
                  name={questionTitle}
                  text={dayjs(answer.value.str_val, DATE_INPUT_FORMAT).format(DATE_OUTPUT_FORMAT)}
                />
              );
            default: {
              const key = Object.keys(answer.value)[0] as keyof SurveyAnswer['answer']['value'];
              return <TextQuestion name={questionTitle} text={answer.value[key]?.toString()} />;
            }
          }
        };
        if (!getAnsweredQuestion()) return null;
        return (
          <ListItem key={question_id} disableGutters sx={{ paddingBlock: 2 }}>
            {getAnsweredQuestion()}
          </ListItem>
        );
      })}
      <Typography variant="body1" align="center" sx={{ paddingBlock: 5 }}>
        <FormattedMessage
          id="inbox.survey.questions.hint"
          defaultMessage="Only answered questions are visible"
        />
      </Typography>
    </List>
  );
};
