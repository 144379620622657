import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { SelectChangeEvent } from '@mui/material';
import {
  commonFields,
  useFetchUserSettings,
  useLanguageStore,
  useUpdateUserSettings,
} from '@trustyou/shared';
import { Box, Button, ContentPane, DiscardChangesModal, Typography, snackbar } from '@trustyou/ui';

import { LanguageSwitcher } from '../../../../components';
import { styles } from '../../styles';

export default function LanguageChange() {
  const intl = useIntl();
  const { locale, updateLocale } = useLanguageStore();

  const [chosenLanguage, setChosenLanguage] = useState(locale);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const {
    data: userSettings,
    isSuccess: isSuccessUserSettings,
    isLoading: isLoadingUserSettings,
    isRefetching: isRefetchingUserSettings,
    refetch,
  } = useFetchUserSettings();
  useEffect(() => {
    if (isSuccessUserSettings) {
      const language = userSettings.settings.language;
      setChosenLanguage(language);
      if (language !== 'auto' && language !== locale) {
        updateLocale(language);
      }
    }
  }, [isSuccessUserSettings, locale, updateLocale, userSettings?.settings.language]);

  const savedLanguage = userSettings?.settings?.language;

  const updateUserSettings = useUpdateUserSettings();

  const submitLanguageChange = () => {
    updateLocale(chosenLanguage as string);
    updateUserSettings.mutate(
      {
        settings: {
          signature: userSettings?.settings.signature || '',
          language: chosenLanguage,
        },
      },
      {
        onSuccess: () => {
          snackbar.success(intl.formatMessage(commonFields.changesSaved));
          refetch();
        },
      }
    );
  };

  const handleChange = (event: SelectChangeEvent) => {
    setChosenLanguage(event.target.value as string);
  };

  const cancelClicked = () => {
    if (savedLanguage !== chosenLanguage) {
      setShowCancelConfirmation(true);
    }
  };

  const isLoading =
    isLoadingUserSettings || isRefetchingUserSettings || updateUserSettings.isPending;

  return (
    <ContentPane bodyStyles={{ justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="h6" color="text.primary">
          <FormattedMessage {...commonFields.language} />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="user.settings.language.description"
            defaultMessage="Choose your preferred language to be used in the application"
          />
        </Typography>
      </Box>
      <Box mt={5}>
        <LanguageSwitcher value={chosenLanguage} onChange={handleChange} />
      </Box>
      <Box sx={styles.pageActions}>
        <Button
          color="primary"
          variant="text"
          disabled={savedLanguage === chosenLanguage || isLoading}
          onClick={cancelClicked}
          sx={{ marginRight: 2 }}
        >
          <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          type="submit"
          variant="contained"
          data-testid="save-changes-language"
          disabled={savedLanguage === chosenLanguage || isLoading}
          onClick={submitLanguageChange}
        >
          <FormattedMessage {...commonFields.saveChanges} />
        </Button>
      </Box>
      <DiscardChangesModal
        onClose={() => {
          setShowCancelConfirmation(false);
        }}
        onDiscard={() => {
          setChosenLanguage(savedLanguage || locale);
          setShowCancelConfirmation(false);
        }}
        open={showCancelConfirmation}
      />
    </ContentPane>
  );
}
