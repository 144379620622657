export const QUESTION_LIST = 'question_list';
export const SURVEY_LIST = 'survey_list';
export const SURVEY_CREATE = 'survey_create';
export const QUESTIONNAIRE_LIST = 'questionnaire_list';
export const QUESTIONNAIRE_LIST_BY_ENTITY = 'questionnaire_list_by_entity';
export const ENTITY_LIST = 'entity_list';
export const CONTENT_LIST = 'content_list';
export const FETCH_VISIT_DATAPOINT = 'visit_datapoint';
export const CREATE_VISIT_DATAPOINT = 'create_visit_datapoint';
export const UPDATE_VISIT_DATAPOINT = 'update_visit_datapoint';
export const DELETE_VISIT_DATAPOINT = 'delete_visit_datapoint';
