import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import {
  DEFAULT_SORT_KEY,
  type Entity,
  FETCH_INFINITE_ENTITIES,
  useEntityCount,
  useInfiniteEntities,
} from '@trustyou/shared';
import { Alert, Autocomplete, Box, Grid, Stack, TextField, Typography } from '@trustyou/ui';

import { AIGuidesForm } from './ai-guides-form';

import { MAX_ENTITIES } from '../../../../constants';
import type { ResponseAISettingsFormData } from '../../../../types';
import { ProgressLine } from '../../../progress-line';
import { SettingsSection } from '../../../settings-section/settings-section';

export function AIEntityGuides() {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const [selectedEntity, setSelectedEntity] = useState<Entity | undefined | null>();
  const [searchKey, setSearchKey] = useState<string>('');
  const { data, isLoading } = useInfiniteEntities(MAX_ENTITIES, DEFAULT_SORT_KEY, searchKey);
  const { data: totalEntityCount = 0, isPending: isEntityCountLoading } = useEntityCount();

  const [selectedIndex, setSelectedIndex] = useState<number>();

  const { control, resetField } = useFormContext<ResponseAISettingsFormData>();
  const { append, fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'guides', // unique name for your Field Array
  });
  const watchedFields = useWatch({
    control,
    name: 'guides',
  });

  const onSearch = (value: string) => {
    setSearchKey(value);
    queryClient.removeQueries({ queryKey: [FETCH_INFINITE_ENTITIES] });
  };

  useEffect(() => {
    // preload entity if only one is accessible for the user
    if (totalEntityCount === 1) {
      setSelectedIndex(0);
      if (!fields[0]?.entity_id && data?.pages[0].data[0].id) {
        append({ entity_id: data?.pages[0].data[0].id, guides: [] });
      }
    }
  }, [append, totalEntityCount, fields, data?.pages, selectedIndex]);

  return isEntityCountLoading ? null : (
    <Box>
      <Box>
        <SettingsSection
          title={intl.formatMessage({
            id: 'inbox.settings.responseAi.title',
            defaultMessage: 'AI Guides',
          })}
          description={intl.formatMessage({
            id: 'inbox.settings.responseAi.description',
            defaultMessage:
              'AI Guides assist responseAI in creating a response that addresses specific complaints or compliments. Each guide consists of a response to a specific complaint or praise. Whenever the complaint or praise is used in a review, the guide is integrated into the response without repeating the words verbatim.',
          })}
        />
        <Alert
          severity="error"
          sx={{
            backgroundColor: 'transparent',
            color: 'text.secondary',
            fontSize: 'initial',
            p: 0,
            mt: 2,
            '& .MuiAlert-icon': { color: 'text.disabled', alignItems: 'center' },
            '& .MuiAlert-message': { pt: 0.75 },
          }}
        >
          <FormattedMessage
            id="inbox.settings.responseAi.warning"
            defaultMessage="Make sure the description is obviously positive or negative. You only need to write the guide once - responseAI will translate it when needed."
          />
        </Alert>
      </Box>
      {totalEntityCount > 1 && (
        <Stack paddingTop={6} gap={1}>
          <Typography variant="subtitle1" fontWeight={500}>
            <FormattedMessage id="inbox.settings.responseAi.entity.title" defaultMessage="Entity" />
          </Typography>
          <Autocomplete
            options={data?.pages[0].data || []}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  isLoading
                    ? intl.formatMessage({ id: 'inbox.loading', defaultMessage: 'loading' })
                    : intl.formatMessage({
                        id: 'inbox.settings.responseAi.entity.placeholder',
                        defaultMessage: 'start typing to select an entity',
                      })
                }
              />
            )}
            value={selectedEntity || null}
            onChange={async (_, newValue) => {
              setSelectedEntity(newValue);
              if (!newValue?.id) return;
              resetField('guides');
              const index = watchedFields.findIndex((guide) => guide.entity_id === newValue.id);
              if (index >= 0) {
                // if entity is already in the list, select it
                setSelectedIndex(index);
              } else {
                // create new list entry for the guides
                const newEntry = {
                  entity_id: newValue.id,
                  guides: [],
                };
                append(newEntry);
                setSelectedIndex(watchedFields.length);
              }
            }}
            inputValue={searchKey}
            onInputChange={(_, newInputValue) => {
              onSearch(newInputValue);
            }}
            autoComplete
            disableClearable={!!selectedEntity}
          />
        </Stack>
      )}
      {selectedIndex !== undefined && watchedFields[selectedIndex]?.entity_id && (
        <>
          <Box sx={{ paddingBlock: 2, marginTop: 3 }}>
            <ProgressLine value={watchedFields[selectedIndex]?.guides.length ?? 0} />
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                <FormattedMessage
                  id="inbox.settings.descriptionOfPraise.title"
                  defaultMessage="Description of complaint / praise"
                />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <FormattedMessage
                  id="inbox.settings.descriptionOfPraise.description"
                  defaultMessage="A positive or negative phrase, e.g. “no vegan breakfast options”, or “friendly staff”"
                />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                <FormattedMessage
                  id="inbox.settings.responseToPraise.title"
                  defaultMessage="Response to complaint / praise"
                />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <FormattedMessage
                  id="inbox.settings.responseToPraise.description"
                  defaultMessage="Example answer to the complaint of praise"
                />
              </Typography>
            </Grid>
            <AIGuidesForm entityIndex={selectedIndex} />
          </Grid>
        </>
      )}
    </Box>
  );
}
