import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  CategoryAndEntityFilters,
  DropdownFilters,
  type FiltersForm,
  type QueryOptions,
  ShowFormControlLabel,
  StyledDivider,
  type View,
  ViewsDropdown,
  countActiveFilters,
  getViewFilters,
  useFetchSemaLanguages,
  useFetchSources,
  useFetchSurveyTypes,
  usePredefinedViews,
  useReviewsData,
} from '@trustyou/inbox';
import {
  useChangelingStore,
  useInboxStore,
  useLanguageStore,
  useMembershipStore,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  Button,
  ComposableDrawerWithStickyFooter,
  Stack,
  Typography,
} from '@trustyou/ui';
import * as yup from 'yup';

import { commonMessages, inboxFiltersStep } from '../../../../constants/messages';
import { useNewReportStore } from '../../../../store';
import { Footer } from '../footer';
import styles from '../styles';

export const schema = yup.object().shape({
  status: yup.array().of(yup.string()),
  rangename: yup.string(),
  score: yup.array().of(yup.string()),
  source: yup.array().of(yup.string()),
  survey_type: yup.array().of(yup.string()),
});

export const InboxFilterStep = () => {
  const { isChangeling } = useChangelingStore();
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm<FiltersForm>({ resolver: yupResolver(schema) });
  const { handleSubmit } = methods;

  const intl = useIntl();
  const { prevStep, nextStep } = useNewReportStore();

  // Fetch data sources and other necessary data
  const { allReviewsView } = usePredefinedViews();
  const { locale } = useLanguageStore();
  const { data: sources = {} } = useFetchSources();
  const { data: languages = {} } = useFetchSemaLanguages(locale as any);
  const { data: surveyTypes = [] } = useFetchSurveyTypes();

  const { dataGrid, currentView, setCurrentView } = useInboxStore();
  const { membership } = useMembershipStore();
  const activeFiltersCount = countActiveFilters(currentView.filters ?? {}, sources, languages);

  //Fetching users selected entites
  const queryOptions: QueryOptions = {
    sortModel: dataGrid.sorting?.sortModel,
    page: dataGrid.pagination?.paginationModel?.page,
    pageSize: dataGrid.pagination?.paginationModel?.pageSize,
  };
  // Reason to call this is to get the data of user's selected entities
  //TODO: fix this api call in future because it currently fetches all the data related to reviews
  useReviewsData(queryOptions, currentView.filters ?? {});

  // Handle filter changes from Drawer
  const handleFilter = (view: View) => {
    if (view.filters && !view.filters?.relative_timerange && !view.filters?.preset_timerange) {
      view.filters.relative_timerange = 'all-time';
    }
    setCurrentView({ ...view, membership_id: membership?.id });
  };

  const normalizeFilters = (search_terms: string[], view: View) => {
    const allReviewsViewWithSearchTerms = {
      ...allReviewsView,
      filters: { ...allReviewsView.filters, search_terms },
    };
    const customViewWithChangedFilters = {
      ...view,
      filters: {
        ...view?.filters,
        search_terms,
      },
    };
    const normalizedView = view ? customViewWithChangedFilters : allReviewsViewWithSearchTerms;
    return normalizedView;
  };

  const handleApplyFilters = (newView: View) => {
    handleFilter(normalizeFilters(currentView.filters?.search_terms ?? [], newView));
  };

  const applyFilters = (form: FiltersForm) => {
    const filters = getViewFilters(
      form,
      Object.keys(sources)?.length,
      Object.keys(languages)?.length,
      surveyTypes?.length
    );
    const viewToApply = {
      label: '',
      filters: { ...filters, search_terms: currentView.filters?.search_terms },
    };
    handleApplyFilters(viewToApply);
    setIsOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <Box sx={styles.stepBody}>
        <Stack sx={{ width: '100%' }}>
          <Stack spacing={5} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ width: 510 }}>
              <Typography variant="h6">
                {intl.formatMessage(inboxFiltersStep.defineFiltersTitle)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {intl.formatMessage(inboxFiltersStep.defineFiltersDescription)}
              </Typography>
            </Box>
            <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'flex-start', width: 510 }}>
              <ViewsDropdown isReports />
              <Button variant="contained" onClick={() => setIsOpen(true)}>
                <FormattedMessage {...commonMessages.filters} />
                {activeFiltersCount > 0 && `(${activeFiltersCount})`}
              </Button>
            </Stack>
          </Stack>
          <ComposableDrawerWithStickyFooter
            anchor="right"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            PaperProps={{
              sx: {
                width: '320px',
                paddingTop: isChangeling ? 8 : 0,
              },
            }}
          >
            <ComposableDrawerWithStickyFooter.Content>
              <Stack spacing={3} sx={{ paddingBlock: 1 }}>
                <DropdownFilters />
                <CategoryAndEntityFilters />
                <StyledDivider />
                <ShowFormControlLabel />
                <StyledDivider />
              </Stack>
            </ComposableDrawerWithStickyFooter.Content>
            <ComposableDrawerWithStickyFooter.Footer
              primaryButton={
                <Button onClick={handleSubmit(applyFilters)} type="button" variant="contained">
                  <FormattedMessage id="inbox.action.apply" defaultMessage="Apply" />
                </Button>
              }
              secondaryButton={
                <Button onClick={() => setIsOpen(false)} type="button" color="inherit">
                  <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
                </Button>
              }
              sx={{ boxShadow: 5 }}
            />
          </ComposableDrawerWithStickyFooter>
        </Stack>
      </Box>
      <Footer onClickBack={prevStep} onClickNext={nextStep} />
      <BackdropSpinner isLoading={false} />
    </FormProvider>
  );
};
