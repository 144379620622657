import { useQuery } from '@tanstack/react-query';
import { $assApi, type PaginationRequest, SURVEY_BASE_PATH } from '@trustyou/shared';

import type { Question_Output } from '../../client';
import type { Content } from '../../types/content';
import type { ContentListProps, MultipleContentListProps } from '../../types/survey';
import { CONTENT_LIST, QUESTION_LIST } from '../constants/query-keys';

async function fetchQuestionList({ limit, offset }: PaginationRequest): Promise<Question_Output[]> {
  const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/question/list`, {
    params: {
      limit,
      offset,
    },
  });
  return data;
}

async function fetchContentList({ type, limit, offset }: ContentListProps): Promise<Content[]> {
  const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/content/list`, {
    params: {
      type,
      limit,
      offset,
    },
  });
  return data;
}

async function fetchMultipleContentLists({
  types,
  limit,
  offset,
}: MultipleContentListProps): Promise<Content[]> {
  // Approach A: Multiple requests
  const requests = types.map((type) => fetchContentList({ type, limit, offset }));
  const multipleData = await Promise.all(requests);
  return multipleData.flat();

  // TODO: Waiting for backend.
  // Approach B: Multiple values for the same query parameter
  // const params = new URLSearchParams();
  // types.forEach((type) => params.append('type', type));
  // const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/content/list`, { params });
  // return data;
}

export function useQuestions(pagination: PaginationRequest) {
  return useQuery({
    queryKey: [QUESTION_LIST],
    queryFn: () => fetchQuestionList(pagination),
  });
}

export function useContents({ types, limit, offset }: MultipleContentListProps) {
  return useQuery({
    queryKey: [CONTENT_LIST, { types }],
    queryFn: () => fetchMultipleContentLists({ types, limit, offset }),
  });
}
