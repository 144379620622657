import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from '@trustyou/ui';

import { RenderQuestions } from './render-questions';

import { Question } from '../../../components/feedback/components/question';
import {
  SurveyMessageContent,
  SurveySectionTitle,
} from '../../../components/feedback/static-elements';
import type {
  PaginatedSurveyProps,
  SurveyEditorContentProps,
  SurveyQuestionnaireEditorContentOutput,
} from '../../../types/survey';

const PaginationKeytoName: { [key: string]: JSX.Element } = {
  'survey-content': (
    <FormattedMessage
      id="survey.survey-editor.page.survey-content"
      defaultMessage="Survey content"
    />
  ),
  'thank-you-page': (
    <FormattedMessage id="survey.survey-editor.page.thank-you-page" defaultMessage="Thank you" />
  ),
};

export const SurveyQuestionnaireEditorPreview = ({ activeStep }: { activeStep: number }) => {
  const { watch } = useFormContext();
  const intl = useIntl();

  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const editorWelcomeMessage = watch('surveyQuestionnaireEditorWelcomeMessage');
  const surveyContent = watch('surveyContent') as SurveyEditorContentProps[];
  const editorThankYouMessage = watch('surveyQuestionnaireEditorThankyouMessage');

  const [paginatedSurvey, setPaginatedSurvey] = useState<PaginatedSurveyProps[]>([
    { 'survey-content': [editorWelcomeMessage, surveyContent] },
    { 'thank-you-page': [editorThankYouMessage] },
  ]);
  const [selectedPreviewPage, setSelectedPreviewPage] = useState<string>('survey-content');

  const isContentExist =
    (editorWelcomeMessage && editorWelcomeMessage.id !== 'no-welcome-message') ||
    surveyContent?.length > 0 ||
    editorThankYouMessage;

  useEffect(() => {
    if (activeStep === 5) {
      setSelectedPreviewPage('thank-you-page');
    } else if ([0, 1, 2, 3, 4].includes(activeStep)) {
      setSelectedPreviewPage('survey-content');
    }
  }, [activeStep]);

  useEffect(() => {
    setPaginatedSurvey([
      { 'survey-content': [editorWelcomeMessage, surveyContent] },
      { 'thank-you-page': [editorThankYouMessage] },
    ]);
  }, [editorWelcomeMessage, surveyContent, editorThankYouMessage]);

  return (
    <Stack spacing={2} sx={{ marginInline: 5 }}>
      <FormControl sx={{ width: 160, marginTop: 2 }}>
        <InputLabel>
          <FormattedMessage id="survey.survey-editor.preview" defaultMessage="Preview" />
        </InputLabel>
        <Select
          label={<FormattedMessage id="survey.survey-editor.preview" defaultMessage="Preview" />}
          size="small"
          value={selectedPreviewPage}
          onChange={(event) => setSelectedPreviewPage(event.target.value as string)}
        >
          {paginatedSurvey.map((page, index) => {
            const pageName = Object.keys(page)[0];
            return (
              <MenuItem key={index} value={pageName}>
                {PaginationKeytoName[pageName]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {!isContentExist && (
        <Question
          name="initial-preview"
          title={intl.formatMessage({
            id: 'survey.survey-editor.initial-preview-card.title',
            defaultMessage: 'This is a preview of your Survey',
          })}
          description={intl.formatMessage({
            id: 'survey.survey-editor.initial-preview-card.description',
            defaultMessage: 'Start adding content',
          })}
        />
      )}
      <Box sx={{ maxHeight: '600px', overflow: 'auto' }}>
        {selectedPreviewPage === 'survey-content' && (
          <Box>
            {editorWelcomeMessage && editorWelcomeMessage.id !== 'no-welcome-message' && (
              <SurveyMessageContent
                title={
                  editorWelcomeMessage?.title?.[defaultSurveyLanguage] ??
                  editorWelcomeMessage?.title?.[editorWelcomeMessage.default_language ?? 'en'] ??
                  ''
                }
                description={
                  editorWelcomeMessage?.description?.[defaultSurveyLanguage] ??
                  editorWelcomeMessage?.description?.[
                    editorWelcomeMessage.default_language ?? 'en'
                  ] ??
                  ''
                }
              />
            )}
            {surveyContent && surveyContent.length > 0 && (
              <Stack spacing={2}>
                {surveyContent.map((content: SurveyEditorContentProps, index: number) => {
                  const contentLang = content.default_language ?? 'en';
                  if (content.type === 'section') {
                    return (
                      <Stack key={content.id}>
                        <SurveySectionTitle
                          title={content.title[defaultSurveyLanguage] ?? content.title[contentLang]}
                        />
                        {content.questions?.map(
                          (question: SurveyQuestionnaireEditorContentOutput, index: number) => (
                            <RenderQuestions key={index} {...question} />
                          )
                        )}
                      </Stack>
                    );
                  }
                  return <RenderQuestions key={index} {...content} />;
                })}
              </Stack>
            )}
          </Box>
        )}
        {selectedPreviewPage === 'thank-you-page' && (
          <Box>
            {editorThankYouMessage && (
              <SurveyMessageContent
                title={
                  editorThankYouMessage?.title?.[defaultSurveyLanguage] ??
                  editorThankYouMessage?.title?.[editorThankYouMessage.default_language ?? 'en'] ??
                  ''
                }
                description={
                  editorThankYouMessage?.description?.[defaultSurveyLanguage] ??
                  editorThankYouMessage?.description?.[
                    editorThankYouMessage.default_language ?? 'en'
                  ] ??
                  ''
                }
              />
            )}
          </Box>
        )}
      </Box>
    </Stack>
  );
};
