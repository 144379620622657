import type { Content } from '../types/content';
import type {
  SurveyEditorContentProps,
  SurveyQuestionnaireEditorContentOutput,
} from '../types/survey';

type FilterChosenContentsProps = {
  allItems: Content[];
  chosenItems?: SurveyEditorContentProps[];
};
export function filterChosenContents({ allItems, chosenItems }: FilterChosenContentsProps) {
  const chosenQuestionIds = chosenItems?.map((item) => item.id) || [];
  const chosenSectionQuestionIds = (chosenItems ?? []).flatMap((item) =>
    item.questions
      ? item.questions.map((question: SurveyQuestionnaireEditorContentOutput) => question.id)
      : []
  );
  const allChosenContentIds = [...chosenQuestionIds, ...chosenSectionQuestionIds];
  return allItems.filter((item) => !allChosenContentIds.includes(item.id));
}

// export function paginateSurvey(survey?: SurveyEditorContentProps[]) {
//   const pages = [];
//   let currentPage: SurveyEditorContentProps[] = [];

//   survey?.forEach((item: SurveyEditorContentProps) => {
//     if (item.type === 'page-break') {
//       pages.push(currentPage);
//       currentPage = [];
//     } else {
//       currentPage.push(item);
//     }
//   });

//   if (currentPage.length > 0) {
//     pages.push(currentPage);
//   }

//   return pages;
// }
