import { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Button, Checkbox, FormControlLabel, Stack } from '@trustyou/ui';

import { EmailDetailsDrawer } from './email-details-drawer';

import { useReview } from '../../../hooks';
import type { ResponseFormSchema, ResponseTranslationOption } from '../../../types';

type EmailDetailsProps = {
  radioValue?: ResponseTranslationOption;
};

export function PrivateResponseSection({ radioValue }: EmailDetailsProps) {
  const { control } = useFormContext<ResponseFormSchema>();
  const alsoSendViaEmail = useWatch({ control, name: 'alsoSendViaEmail' });
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const isPublic = reviewRoot?.survey?.privacy_level === 'public';

  const [isEmailDetailsDrawerOpen, setIsEmailDetailsDrawerOpen] = useState(false);

  return (
    <Stack spacing={1} sx={{ alignItems: 'start' }}>
      {isPublic && (
        <Controller
          name="alsoSendViaEmail"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox {...field} checked={field.value ?? false} onClick={field.onChange} />
              }
              label={
                <FormattedMessage
                  id="inbox.response.email-details.also-send-via-email"
                  defaultMessage="Also send a response via email"
                />
              }
            />
          )}
        />
      )}
      {alsoSendViaEmail && (
        <>
          <Button variant="outlined" onClick={() => setIsEmailDetailsDrawerOpen(true)}>
            <FormattedMessage
              id="inbox.response.email-details.edit-details"
              defaultMessage="Edit email details"
            />
          </Button>
          <EmailDetailsDrawer
            onClose={() => setIsEmailDetailsDrawerOpen(false)}
            isOpen={isEmailDetailsDrawerOpen}
            radioValue={radioValue}
          />
        </>
      )}
    </Stack>
  );
}
