/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { yupResolver } from '@hookform/resolvers/yup';
import { type MutateOptions, useQueryClient } from '@tanstack/react-query';
import { validationErrors } from '@trustyou/shared';
import { Box, CircularProgress, Stack, Typography, snackbar } from '@trustyou/ui';
import * as yup from 'yup';

import { ExistingResponse, NonRespondableMessage, ResponseInfo } from './non-editable-response';
import { ReadOnlyRoleAlert, ResponseForm, UsedAIGuides } from './response-form';
import { ResponseTabs } from './response-tabs';

import type { LanguageEnum, TranslateIn } from '../../client';
import { INBOX_TOOLBAR_HEIGHT, METADATA_SECTION_HEIGHT } from '../../constants';
import {
  type LanguageSource,
  newAbortController,
  resetAbortController,
  updateReviewStatusById,
  useGenerateResponseAI,
  useLanguage,
  useResponseAIProfile,
  useReview,
  useSaveResponse,
  useTranslateViaResponseAI,
} from '../../hooks';
import { useResponseAIPermission } from '../../hooks/permissions/use-response-ai-permission';
import useSaveResponsePermission from '../../hooks/permissions/use-save-response-permission';
import { useStore } from '../../store/store';
import {
  type ErrorWithResponse,
  type Language,
  type LanguageItem,
  type ResponseAITranslateData,
  type ResponseFormSchema,
  type ResponseResult,
  ResponseTabsEnum,
  type ResponseTranslationOption,
} from '../../types';
import { isDeletionConfirmed, isDeletionPendingOrRequested } from '../../utils/review';
import type { Option } from '../dropdowns/dropdown-chip';
import { ReplaceDialog } from '../response-ai/replace-dialog';
import { DeletedReviewAlert, MarkedAsDeletedReviewAlert } from '../review-deletion';
import {
  InappropriateProcessingReviewAlert,
  MarkedAsInappropriateReviewAlert,
} from '../survey-moderation';
import { InappropriateReviewAlert } from '../survey-moderation/inappropriate-review-alert';

// TODO: Refactor this component to encapsulate different logic into custom hooks, reducing the number of states and `useEffect`.
export function Response() {
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });

  // Zustand store
  const entityData = useStore.use.entityData();
  const generationLanguage = useStore.use.generationLanguage();
  const hasGeneratedResponse = useStore.use.hasGeneratedResponse();
  const hasExistingResponse = useStore.use.hasExistingResponse();
  const updateIsSubmitDisabled = useStore.use.updateIsSubmitDisabled();
  const updateIsResponseFormDirty = useStore.use.updateIsResponseFormDirty();
  const updateHasExistingResponse = useStore.use.updateHasExistingResponse();
  const updateHasGeneratedResponse = useStore.use.updateHasGeneratedResponse();
  const updateAcceptsDirectResponse = useStore.use.updateAcceptsDirectResponse();

  // Convenient destructuring
  const {
    meta,
    id: reviewRootId = '',
    response,
    review,
    survey,
    deletion,
    moderation,
  } = reviewRoot ?? {};
  const { score, title, text, respondable } = review ?? {};

  // i18n
  const intl = useIntl();
  const invalidError = intl.formatMessage(validationErrors.invalidEmail);
  const requiredError = intl.formatMessage(validationErrors.required);
  const subjectText = intl.formatMessage(
    {
      id: 'inbox.response.email-details.default-subject',
      defaultMessage: 'Reply from {entityName}',
    },
    { entityName: entityData?.name }
  );

  // Translations within the Response component
  const {
    getLanguageSourceByCode,
    getLanguageSourceByLanguage,
    getFormattedLanguageCode,
    fallBackLanguage,
    fallBackLanguageSource,
  } = useLanguage();

  // Third-party hooks and custom hooks
  const queryClient = useQueryClient();
  const { accessTokenPayload } = useOidcAccessToken();
  const hasResponseAIPermission = useResponseAIPermission();
  const { data: aiSettings } = useResponseAIProfile();
  const saveResponse = useSaveResponse();
  const isSaveResponseAllowed = useSaveResponsePermission();

  const headerRef = useRef<HTMLDivElement>(null);

  // State
  const [radioValue, setRadioValue] = useState<ResponseTranslationOption>('original');
  const [translationTarget, setTranslationTarget] = useState<
    ResponseTranslationOption | undefined
  >();
  const [translationLanguage, setTranslationLanguage] = useState<LanguageSource>();
  const [defaultLanguage, setDefaultLanguage] = useState<LanguageSource>(fallBackLanguageSource);
  const [guidesUsed, setGuidesUsed] = useState<string[] | null>();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [isRespondable, setIsRespondable] = useState(respondable);
  const [isReplaceDialogOpen, setIsReplaceDialogOpen] = useState(false);

  // Derived state
  const RESPONSE_SECTION_HEADER_HEIGHT = hasExistingResponse ? METADATA_SECTION_HEIGHT : '0px';
  const isPrivate = survey?.privacy_level === 'private';
  const hasScoreTitleOrText = Boolean(score !== undefined || title?.length || text?.length);
  const isTranslatedTextSelected = radioValue === 'translated';
  const isMarkedAsDeleted = isDeletionPendingOrRequested(deletion);
  const isDeleted = isDeletionConfirmed(deletion);
  const isModerationRequested = moderation?.status === 'requested';
  const isModerationPropagating = moderation?.status === 'propagating';
  const isModerationApproved = moderation?.status === 'approved';

  // UI Visibility
  const shouldShowResponseForm =
    isRespondable &&
    !hasExistingResponse &&
    !isMarkedAsDeleted &&
    !isDeleted &&
    !isModerationRequested &&
    !isModerationApproved &&
    !isModerationPropagating;
  const shouldShowResponseAI = hasResponseAIPermission && hasScoreTitleOrText;
  const shouldShowGreyArea = !shouldShowResponseForm;

  // State initialized from some other derived state
  const [currentTab, setCurrentTab] = useState<ResponseTabsEnum>(
    shouldShowResponseAI ? ResponseTabsEnum.RESPONSE_AI : ResponseTabsEnum.TEMPLATE
  );

  // Form
  const schema = yup.object().shape({
    response: hasExistingResponse ? yup.string() : yup.string().required(),
    alsoSendViaEmail: yup.boolean().default(false),
    emailDetails: yup.object().shape({
      subject: yup.string().required(requiredError),
      from: yup.string().email(invalidError).required(requiredError),
      cc: yup.array().of(yup.string().email(invalidError)),
      bcc: yup.array().of(yup.string().email(invalidError)),
    }),
  });
  const methods = useForm<ResponseFormSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { isDirty },
  } = methods;
  const responseTextWatch = watch('response');
  const translatedResponseTextWatch = watch('translatedResponse');
  const initialEmailDetails: ResponseFormSchema['emailDetails'] = {
    subject: subjectText,
    body: '',
    from: accessTokenPayload.email,
    attachReview: true,
  };

  useEffect(() => {
    updateAcceptsDirectResponse(meta?.directly_respondable ?? false);
  }, [meta?.directly_respondable]);

  useEffect(() => {
    // reset everything when user navigates away (i.e. by refreshing/browser back)
    return () => {
      resetResponseValues();
    };
  }, []);

  useEffect(() => {
    // reset everything when review changes
    resetResponseValues();
  }, [reviewRootId]);

  useEffect(() => {
    // enable leave dialog when i.e. back to inbox icon is clicked
    updateIsResponseFormDirty?.(isDirty || isGenerating);
  }, [isDirty, isGenerating]);

  useEffect(() => {
    /** disables submit button when
     * - is read only role
     * - selected (generated) text field is empty
     * - or the general form is not dirty or valid
     */
    if (!isSaveResponseAllowed) {
      updateIsSubmitDisabled(true);
      return;
    }

    if (hasGeneratedResponse) {
      if (radioValue === 'original') {
        updateIsSubmitDisabled(!responseTextWatch);
      } else {
        updateIsSubmitDisabled(!translatedResponseTextWatch);
      }
      return;
    }
    updateIsSubmitDisabled(!isDirty || !responseTextWatch);
  }, [
    isDirty,
    hasGeneratedResponse,
    radioValue,
    responseTextWatch,
    translatedResponseTextWatch,
    isSaveResponseAllowed,
  ]);

  const resetResponseValues = () => {
    resetAbortController();

    // update review booleans when review changes
    updateHasExistingResponse(!!response);
    updateAcceptsDirectResponse(meta?.directly_respondable ?? false);
    setIsRespondable(respondable);

    // reset response ai
    setIsGenerating(false);
    updateHasGeneratedResponse(false);
    setGuidesUsed(undefined);
    setTranslationLanguage(undefined);
    setTranslationTarget(undefined);

    // clean the inputs
    setRadioValue('original');
    reset({
      response: '',
      translatedResponse: '',
      alsoSendViaEmail: isPrivate,
      emailDetails: initialEmailDetails,
    });
  };

  const generateResponseAI = useGenerateResponseAI();

  const handleGenerateResponse = ({
    replace,
    skipDialogNextTime,
  }: { replace?: boolean; skipDialogNextTime?: boolean } = {}) => {
    if (replace) {
      if (isReplaceDialogOpen) {
        setIsReplaceDialogOpen(!isReplaceDialogOpen);
      }
      if (skipDialogNextTime) {
        localStorage.setItem('skipDialogBeforeGenerateAnother', 'true');
      }
    } else {
      const skipDialog = Boolean(localStorage.getItem('skipDialogBeforeGenerateAnother'));
      if (hasGeneratedResponse && !skipDialog) {
        setIsReplaceDialogOpen(!isReplaceDialogOpen);
        return;
      }
    }

    // Reset fields. Only show one textbox when generating a response.
    setGuidesUsed(undefined);
    setValue('translatedResponse', undefined);
    setTranslationLanguage(undefined);
    setTranslationTarget(undefined);
    setRadioValue('original');

    newAbortController();
    setIsGenerating(true);
    generateResponseAI.mutate(
      {
        review_id: reviewRootId,
        language: generationLanguage.value,
      },
      {
        onSuccess: async (data: ResponseResult) => {
          setValue('response', data.response_text, {
            shouldDirty: !!data.response_text,
          });
          setGuidesUsed(data.templates_used);

          const responseLang = getLanguageSourceByCode(data.response_language);
          responseLang && setDefaultLanguage(responseLang);

          resetAbortController();
          setIsGenerating(false);
          updateHasGeneratedResponse(true);
          snackbar.success(
            intl.formatMessage({
              id: 'inbox.response.generate.success',
              defaultMessage: 'Response generated',
            })
          );
        },
        onError: () => {
          setIsGenerating(false);
          snackbar.error(
            intl.formatMessage({
              id: 'inbox.response.generate.feedbackTitleErrorGeneral',
              defaultMessage: 'Couldn’t generate a response, please try again',
            })
          );
          resetAbortController();
        },
      }
    );
  };

  const translateViaResponseAI = useTranslateViaResponseAI();
  const translationMutateOptions: MutateOptions<
    ResponseAITranslateData,
    Error,
    TranslateIn,
    unknown
  > = {
    onSuccess: (data) => {
      const fieldId = translationTarget === 'original' ? 'response' : 'translatedResponse';
      setValue(fieldId, data?.text, { shouldDirty: !!data?.text });
      setIsTranslating(false);
      setTranslationTarget(undefined);
    },
    onError: () => {
      setIsTranslating(false);
      setIsGenerating(false);
      setTranslationTarget(undefined);
    },
  };

  const handleChangeLanguage = (newLanguage: Option, textField: ResponseTranslationOption) => {
    setTranslationTarget(textField);

    const newLanguageSource = getLanguageSourceByLanguage(newLanguage as LanguageItem);
    if (textField === 'original') setDefaultLanguage(newLanguageSource);
    else setTranslationLanguage(newLanguageSource);

    const textToTranslate =
      textField === 'original' ? responseTextWatch : translatedResponseTextWatch;
    if (!textToTranslate) return;

    translateViaResponseAI.mutate(
      {
        text: textToTranslate,
        target_language: newLanguage.value as Language,
        tone_of_voice: aiSettings?.tone_of_voice ?? 'formal',
      },
      translationMutateOptions
    );
  };

  /**
   * Updates the text of the unselected field based
   * on the text of the selected field
   * @param updateDefault - if true, updates the text of the default text field
   * @param language - if provided, translates the given text into this language
   */
  const handleUpdateTranslation = (
    updateDefault = isTranslatedTextSelected,
    language?: LanguageEnum
  ) => {
    setIsTranslating(true);
    // keep the language
    const targetLanguage = updateDefault
      ? defaultLanguage?.language_source
      : (language ?? translationLanguage?.language_source);
    let translateLang = getFormattedLanguageCode(targetLanguage?.toLowerCase() ?? '');

    if (!translateLang || targetLanguage === 'AUTO' || targetLanguage === 'UND') {
      // fallback to english
      translateLang = fallBackLanguage;
    }

    // update the text based on the opposite (selected) field
    const text = updateDefault ? getValues('translatedResponse') : getValues('response');
    if (!text) return;

    setTranslationTarget(updateDefault ? 'original' : 'translated');
    translateViaResponseAI.mutate(
      {
        text: text ?? '',
        target_language: translateLang ?? fallBackLanguage,
        tone_of_voice: aiSettings?.tone_of_voice ?? 'formal',
      },
      translationMutateOptions
    );
  };

  const handleSaveResponseWithText = (response: string, translatedResponse?: string) => {
    const publicText = isTranslatedTextSelected ? translatedResponse : response;
    const privateText = getValues('emailDetails.sendDifferentResponse')
      ? getValues('emailDetails.differentResponse')
      : isTranslatedTextSelected
        ? getValues('translatedResponse')
        : getValues('response');
    const shouldIncludePrivateResponse = isPrivate || getValues('alsoSendViaEmail');

    saveResponse.mutate(
      {
        reviewId: reviewRootId,
        payload: {
          // TODO: SIN-390 remove this line to stop sending the author to the backend
          author: accessTokenPayload.name,
          text: isPrivate ? undefined : publicText,
          private_response: shouldIncludePrivateResponse
            ? {
                text: privateText ?? '',
                subject: getValues('emailDetails.subject') ?? '',
                sender: getValues('emailDetails.from'),
                cc: getValues('emailDetails.cc'),
                bcc: getValues('emailDetails.bcc'),
                attach_feedback: getValues('emailDetails.attachReview') ?? false,
              }
            : null,
        },
      },
      {
        onSuccess: () => {
          updateReviewStatusById(queryClient, reviewRootId, 'responded');
          resetResponseValues();
          updateHasExistingResponse(true);
        },
        onError: (err) => {
          const { response } = err as unknown as ErrorWithResponse;
          if (response.data.detail) {
            snackbar.error(response.data.detail);
          } else {
            snackbar.genericError();
          }
        },
      }
    );
  };

  const onSubmit = handleSubmit(({ response, translatedResponse }) => {
    handleSaveResponseWithText(response, translatedResponse);
  });

  if (isRespondable && !hasExistingResponse && !isSaveResponseAllowed) {
    // read only role can ONLY see a banner
    return (
      <Stack justifyContent="center" height="100%" paddingX={3}>
        <ReadOnlyRoleAlert />
      </Stack>
    );
  }

  return (
    <>
      {shouldShowGreyArea && (
        <Stack
          sx={{
            padding: 2,
            alignItems: 'start',
            minHeight: METADATA_SECTION_HEIGHT,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            backgroundColor: (theme) => theme.palette.grey[50],
          }}
          ref={headerRef}
        >
          <ResponseInfo />
        </Stack>
      )}
      <Box
        sx={{
          height: `calc(100% - ${INBOX_TOOLBAR_HEIGHT} - ${RESPONSE_SECTION_HEADER_HEIGHT})`,
          overflowY: 'auto',
        }}
      >
        {isMarkedAsDeleted && <MarkedAsDeletedReviewAlert />}
        {isDeleted && <DeletedReviewAlert deletionRequestDate={deletion?.requested_at ?? ''} />}
        {isModerationRequested && <MarkedAsInappropriateReviewAlert />}
        {isModerationApproved && <InappropriateReviewAlert />}
        {isModerationPropagating && <InappropriateProcessingReviewAlert />}
        {guidesUsed?.length ? <UsedAIGuides guides={guidesUsed} /> : null}
        {isGenerating ? (
          <Stack spacing={2} sx={{ alignItems: 'center', mt: 3 }}>
            <CircularProgress />
            <Typography color="text.disabled">
              <FormattedMessage
                id="inbox.response.generate.loading"
                defaultMessage="Composing response"
              />
            </Typography>
          </Stack>
        ) : (
          <>
            {!isRespondable && <NonRespondableMessage />}
            {isRespondable && hasExistingResponse && <ExistingResponse />}
            {shouldShowResponseForm && (
              <>
                <ResponseTabs
                  currentTab={currentTab}
                  shouldShowResponseAI={shouldShowResponseAI}
                  onChange={(value) => {
                    resetResponseValues();
                    setCurrentTab(value);
                  }}
                />
                <Stack component="form" spacing={4} sx={{ p: 3, maxWidth: '80ch' }}>
                  <FormProvider {...methods}>
                    <ResponseForm
                      translationLanguage={translationLanguage}
                      translationTarget={translationTarget}
                      defaultLanguage={defaultLanguage}
                      isTranslating={isTranslating}
                      radioValue={radioValue}
                      type={currentTab}
                      onChangeSelectedTextbox={setRadioValue}
                      onChangeLanguage={handleChangeLanguage}
                      onChangeTranslationLanguage={setTranslationLanguage}
                      onGenerateResponse={handleGenerateResponse}
                      onUpdateTranslation={handleUpdateTranslation}
                      onSubmit={onSubmit}
                    />
                  </FormProvider>
                </Stack>
              </>
            )}
          </>
        )}
      </Box>
      <ReplaceDialog
        isOpen={isReplaceDialogOpen}
        onClose={() => setIsReplaceDialogOpen(!isReplaceDialogOpen)}
        onSubmit={(isChecked) =>
          handleGenerateResponse({ replace: true, skipDialogNextTime: isChecked })
        }
      />
    </>
  );
}
