import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { iconMap, useLanguageStore } from '@trustyou/shared';
import { Typography } from '@trustyou/ui';

import {
  BooleanQuestion,
  type BooleanQuestionVariant,
  DateQuestion,
  type DateQuestionVariant,
  NPSQuestion,
  NumberQuestion,
  OneToFiveOptions,
  OneToTenOptions,
  ScaleQuestion,
  SelectQuestion,
  TextQuestion,
  type TextQuestionVariant,
} from '../../../components/feedback/question-types';
import type { SurveyQuestionnaireEditorContentOutput } from '../../../types/survey';

export const RenderQuestions = (question: SurveyQuestionnaireEditorContentOutput) => {
  const intl = useIntl();
  const { control } = useFormContext();
  const { locale } = useLanguageStore();
  const userSettingLang = locale.split('-')[0];
  const questionLang = question.default_language ?? 'en';
  const name = question.id ?? '';
  const title = question.title[userSettingLang] ?? question.title[questionLang];
  const description = question.description[userSettingLang] ?? question.description[questionLang];
  const variant = question.primitive.repr;
  const isMandatory = question.mandatory;
  const notApplicable = question.not_applicable;
  const notApplicableLabel =
    question.not_applicable_label?.[userSettingLang] ??
    intl.formatMessage({
      id: 'survey.question.checkbox.not-applicable.label',
      defaultMessage: 'Not applicable',
    });

  switch (question.primitive.type) {
    case 'text': {
      return (
        <TextQuestion
          key={name}
          name={name}
          control={control}
          variant={variant as TextQuestionVariant}
          title={title}
          description={description}
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
          // label={label}
        />
      );
    }
    case 'select': {
      return (
        <SelectQuestion
          key={name}
          name={name}
          control={control}
          title={title}
          description={description}
          variant={'dropdown_single'}
          options={question.primitive.options.map((option) => ({
            value: option.value,
            label: option.label[userSettingLang] ?? option.label[questionLang],
            icon: iconMap[option.icon ?? ''],
            color: option.color,
          }))}
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
        />
      );
    }
    case 'scale': {
      return (
        <ScaleQuestion
          key={name}
          name={name}
          control={control}
          title={title}
          description={description}
          options={
            question.primitive.options?.map((option) => ({
              value: option.value,
              label: option.label[userSettingLang] ?? option.label[questionLang],
              icon: iconMap[option.icon ?? ''],
              color:
                // TODO: check this part
                // eslint-disable-next-line no-self-compare
                (option.color ?? 5 === 5)
                  ? OneToFiveOptions.find((o) => o.value === option.value)?.color
                  : OneToTenOptions.find((o) => o.value === option.value)?.color,
            })) ?? []
          }
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
        />
      );
    }
    case 'nps': {
      return (
        <NPSQuestion
          key={name}
          name={name}
          control={control}
          title={title}
          description={description}
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
        />
      );
    }
    case 'number': {
      return (
        <NumberQuestion
          key={name}
          name={name}
          control={control}
          title={title}
          description={description}
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
        />
      );
    }
    case 'boolean': {
      if (variant === 'checkbox') {
        return (
          <BooleanQuestion
            key={name}
            name={name}
            control={control}
            title={title}
            description={description}
            variant={variant}
            options={[
              {
                label: question.title[userSettingLang] ?? question.title[questionLang],
                value: question.title[userSettingLang] ?? question.title[questionLang],
              },
            ]}
            showMandatoryAsterisk={isMandatory}
            notApplicableCheckbox={
              notApplicable
                ? {
                    checked: false,
                    label: notApplicableLabel,
                    onChange: () => {},
                  }
                : undefined
            }
          />
        );
      }
      // Default `boolean` render. It includes the variant `button_group` for the Yes/No question use case.
      return (
        <BooleanQuestion
          key={name}
          name={name}
          control={control}
          title={title}
          description={description}
          variant={variant as BooleanQuestionVariant}
          options={
            question.primitive.options?.map((option) => ({
              value: option.value,
              label: option.label[userSettingLang] ?? option.label[questionLang],
              icon: iconMap[option.icon ?? ''],
              color: option.color,
            })) ?? []
          }
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
        />
      );
    }
    case 'date': {
      const variantMap: Record<string, DateQuestionVariant> = {
        datepicker: 'day-month-year',
        year_month_picker: 'month-year',
      };
      return (
        <DateQuestion
          key={name}
          name={name}
          control={control}
          title={title}
          description={description}
          variant={variantMap[variant as DateQuestionVariant] ?? variant}
          showMandatoryAsterisk={isMandatory}
          notApplicableCheckbox={
            notApplicable
              ? {
                  checked: false,
                  label: notApplicableLabel,
                  onChange: () => {},
                }
              : undefined
          }
        />
      );
    }
    default:
      return (
        <Typography key={name} sx={{ color: 'orange' }}>
          Unhandled primitive type <b>{question.primitive.type}</b> with id <b>{question.id}</b>
        </Typography>
      );
  }
};
