import { useMutation, useQuery } from '@tanstack/react-query';
import { $assApi, SURVEY_BASE_PATH } from '@trustyou/shared';
import type { AxiosError } from 'axios';

import type { CreateSurveySchema, SurveyWithCount } from '../../client';
import { SURVEY_CREATE, SURVEY_LIST } from '../constants/query-keys';

async function fetchSurveyList(): Promise<SurveyWithCount[]> {
  const { data } = await $assApi.get(`${SURVEY_BASE_PATH}/survey/list`);
  return data;
}

async function createSurvey({ name }: CreateSurveySchema): Promise<{ id: string }> {
  const { data } = await $assApi.post(`${SURVEY_BASE_PATH}/survey/create`, { name });
  return data;
}

export function useSurveys() {
  return useQuery({
    queryKey: [SURVEY_LIST],
    queryFn: fetchSurveyList,
  });
}

export function useCreateSurvey(
  onSuccess?: (data: { id: string }) => void,
  onError?: (error: AxiosError) => void
) {
  return useMutation({
    mutationFn: ({ name }: CreateSurveySchema) => createSurvey({ name }),
    mutationKey: [SURVEY_CREATE],
    onSuccess,
    onError,
  });
}
