import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, type DropResult, Droppable } from 'react-beautiful-dnd';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { faGripDotsVertical, faPlus, faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useChangelingStore, useCustomAttributeDefinitions } from '@trustyou/shared';
import {
  CustomTextFieldWithSeparatedLabel,
  type Datapoint,
  useCreateVisitDatapoint,
  useDeleteVisitDatapoint,
  useUpdateVisitDatapoint,
} from '@trustyou/survey-manager';
import {
  Box,
  Button,
  Checkbox,
  ComposableDrawerWithStickyFooter,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  StyledFontAwesomeIcon,
  TextField,
  Typography,
  snackbar,
} from '@trustyou/ui';

import { DeleteDialog } from './DeleteDialog';

type CustomDataPointDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  isEdit: boolean;
  selectedDataPoint?: Datapoint;
};

const SLIDE_ANIMATION_DURATION = 250;

const types = [
  {
    value: 'open_text',
    label: <FormattedMessage id="common.free-text" defaultMessage="Free text" />,
  },
  {
    value: 'single_select',
    label: <FormattedMessage id="common.single-select" defaultMessage="Single select" />,
  },
  {
    value: 'number',
    label: <FormattedMessage id="common.number" defaultMessage="Number" />,
  },
  {
    value: 'datetime',
    label: <FormattedMessage id="common.date" defaultMessage="Date" />,
  },
  {
    value: 'custom_entity_code',
    label: <FormattedMessage id="common.external-entity-id" defaultMessage="External entity ID" />,
  },
];

export function CustomDataPointDrawer({
  isOpen,
  onClose,
  isEdit,
  selectedDataPoint,
}: CustomDataPointDrawerProps) {
  const intl = useIntl();
  const { isChangeling } = useChangelingStore();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const {
    control,
    register,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm();
  const { fields, append, move, update, remove, replace } = useFieldArray({
    control,
    name: 'singleSelectOptions',
  });
  const {
    fields: referenceFields,
    append: referenceAppend,
    move: referenceMove,
    update: referenceUpdate,
    remove: referenceRemove,
  } = useFieldArray({
    control,
    name: 'alternativeReferences',
  });
  const selectedType = watch('type');

  const { data: customAttributes = [] } = useCustomAttributeDefinitions(
    true,
    '?type=text&unique=true'
  );

  const createVisitDatapoint = useCreateVisitDatapoint();
  const updateVisitDatapoint = useUpdateVisitDatapoint();
  const deleteVisitDatapoint = useDeleteVisitDatapoint();

  useEffect(() => {
    if (isOpen) {
      if (isEdit && selectedDataPoint) {
        reset({
          name: selectedDataPoint.name || '',
          referenceCode: selectedDataPoint.internal_key || '',
          type: selectedDataPoint.type || 'open_text',
          exampleValue: selectedDataPoint.example || '',
          externalEntityID: selectedDataPoint.custom_attribute_key || '',
          datapointMandatory: selectedDataPoint.mandatory || false,
          singleSelectOptions: selectedDataPoint.options || [],
          alternativeReferences: selectedDataPoint.source_column || [],
        });
      } else {
        reset({
          name: '',
          referenceCode: '',
          type: 'open_text',
          exampleValue: '',
          externalEntityID: '',
          datapointMandatory: false,
          singleSelectOptions: [],
          alternativeReferences: [],
        });
      }
    }
  }, [isOpen, isEdit, selectedDataPoint, reset]);

  useEffect(() => {
    if (!isEdit) {
      if (selectedType === 'single_select') {
        replace([{ value: '' }]);
      } else {
        replace([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    move(source.index, destination.index);
  };

  const onDragEndReference = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    referenceMove(source.index, destination.index);
  };

  // TODO: Properly type FormData and use it in `useForm<FormData>` and here `(data: FormData)`
  const onSubmit = (data: any) => {
    const alternativeReferences =
      data.alternativeReferences?.filter(
        (ref: { value: string }) => ref.value && ref.value.trim() !== ''
      ) || [];

    const singleSelectOptions =
      data.singleSelectOptions?.filter(
        (option: { value: string }) => option.value && option.value.trim() !== ''
      ) || [];

    const isAlternativeResourcesFilled = alternativeReferences.length > 0;
    const isOptionsFilled = singleSelectOptions.length > 0;

    const body = {
      name: data.name,
      internal_key: data.referenceCode,
      type: data.type,
      options: data.type === 'single_select' && isOptionsFilled ? singleSelectOptions : null,
      source_column: isAlternativeResourcesFilled ? alternativeReferences : null,
      mandatory: data.datapointMandatory,
      example: data.exampleValue,
      custom_attribute_key: data.externalEntityID,
    };

    isEdit
      ? updateVisitDatapoint.mutate(
          {
            form: body,
            datapoint_id: selectedDataPoint?._id,
          },
          {
            onSuccess: () => {
              snackbar.success(
                intl.formatMessage({
                  id: 'visit-data.data-point-drawer.update.success-message',
                  defaultMessage: 'Custom data point updated',
                })
              );
              onClose();
            },
          }
        )
      : createVisitDatapoint.mutate(body, {
          onSuccess: () => {
            snackbar.success(
              intl.formatMessage({
                id: 'visit-data.data-point-drawer.success-message',
                defaultMessage: 'Custom data point created',
              })
            );
            onClose();
          },
        });
  };

  const handleDelete = () => {
    deleteVisitDatapoint.mutate(selectedDataPoint?._id ?? '', {
      onSuccess: () => {
        snackbar.info(
          intl.formatMessage({
            id: 'visit-data.data-point-drawer.delete.success-message',
            defaultMessage: 'Custom data point deleted',
          })
        );
        onClose();
      },
    });
    onClose();
    setIsDeleteDialogOpen(false);
    reset();
  };

  const FormInput = ({ name, label, isRequired, ...rest }: any) => (
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink>
        {label}
        {isRequired && (
          <Box component="span" sx={{ marginLeft: 0.5, color: 'error.main' }}>
            *
          </Box>
        )}
      </InputLabel>
      <CustomTextFieldWithSeparatedLabel
        fullWidth
        size="small"
        error={!!errors[name]}
        helperText={
          !!errors[name] &&
          intl.formatMessage({ id: 'common.mandatoryField', defaultMessage: 'Mandatory field' })
        }
        {...register(name, { required: isRequired })}
        {...rest}
      />
    </FormControl>
  );

  const SingleSelectOptions = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
        <FormattedMessage id="common.value-options" defaultMessage="Value options" />
        <Box component="span" sx={{ marginLeft: 0.5, color: 'error.main' }}>
          *
        </Box>
      </Typography>
      <Droppable droppableId="droppable-options">
        {(provided) => (
          <Stack
            {...provided.droppableProps}
            ref={provided.innerRef}
            spacing={3}
            sx={{ alignItems: 'flex-start' }}
          >
            {fields.map((field, index) => (
              <Draggable key={field.id} draggableId={field.id.toString()} index={index}>
                {(provided) => (
                  <Stack
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={field.id}
                    sx={{ flexDirection: 'row', width: '100%' }}
                  >
                    <IconButton size="medium">
                      <StyledFontAwesomeIcon icon={faGripDotsVertical} />
                    </IconButton>
                    <Controller
                      name={`singleSelectOptions[${index}].value`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: controllerField }) => (
                        <TextField
                          variant="outlined"
                          fullWidth
                          placeholder={`${intl.formatMessage({ id: 'common.option', defaultMessage: 'Option' })} ${index + 1}`}
                          size="small"
                          hiddenLabel
                          value={controllerField.value}
                          error={!!errors?.singleSelectOptions}
                          onChange={(e) => {
                            controllerField.onChange(e);
                          }}
                          onBlur={(e) => {
                            update(index, {
                              value: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                    {fields.length > 1 && !isEdit && (
                      <IconButton size="medium" onClick={() => remove(index)}>
                        <StyledFontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    )}
                  </Stack>
                )}
              </Draggable>
            ))}
            <Button
              variant="text"
              startIcon={<StyledFontAwesomeIcon icon={faPlus} />}
              onClick={() => append({ value: '' })}
            >
              <FormattedMessage id="common.add-option" defaultMessage="Add option" />
            </Button>
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );

  const AlternativeReferenceOptions = () => (
    <DragDropContext onDragEnd={onDragEndReference}>
      <Stack>
        <Typography variant="subtitle1">
          <FormattedMessage
            id="visit-data.alternative-reference-code.title"
            defaultMessage="Alternative reference code"
          />
        </Typography>
        <Typography variant="body1" color="text.secondary">
          <FormattedMessage
            id="visit-data.alternative-reference-code.description"
            defaultMessage="You can define alternative column names to help us recognize this data point during imports. If you use one of these as column name when uploading data, we’ll automatically link them to this data point."
          />
        </Typography>
      </Stack>
      <Droppable droppableId="droppable-references">
        {(provided) => (
          <Stack
            {...provided.droppableProps}
            ref={provided.innerRef}
            spacing={3}
            sx={{ alignItems: 'flex-start', marginTop: 2 }}
          >
            {referenceFields.map((field, index) => (
              <Draggable key={field.id} draggableId={field.id.toString()} index={index}>
                {(provided) => (
                  <Stack
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={field.id}
                    sx={{ flexDirection: 'row', width: '100%' }}
                  >
                    <IconButton size="medium">
                      <StyledFontAwesomeIcon icon={faGripDotsVertical} />
                    </IconButton>
                    <Controller
                      name={`alternativeReferences[${index}].value`}
                      control={control}
                      render={({ field: controllerField }) => (
                        <TextField
                          variant="outlined"
                          fullWidth
                          placeholder={intl.formatMessage({
                            id: 'common.name',
                            defaultMessage: 'Name',
                          })}
                          size="small"
                          hiddenLabel
                          value={controllerField.value}
                          onChange={(e) => {
                            controllerField.onChange(e);
                          }}
                          onBlur={(e) => {
                            referenceUpdate(index, {
                              value: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                    {referenceFields.length > 0 && (
                      <IconButton size="medium" onClick={() => referenceRemove(index)}>
                        <StyledFontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    )}
                  </Stack>
                )}
              </Draggable>
            ))}
            <Button
              variant="text"
              startIcon={<StyledFontAwesomeIcon icon={faPlus} />}
              onClick={() => referenceAppend({ value: '' })}
            >
              <FormattedMessage id="common.add-option" defaultMessage="Add option" />
            </Button>
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <ComposableDrawerWithStickyFooter
      open={isOpen}
      anchor="right"
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 600,
          paddingTop: isChangeling ? 8 : 0,
        },
      }}
      SlideProps={{ appear: true, timeout: SLIDE_ANIMATION_DURATION }}
    >
      <ComposableDrawerWithStickyFooter.Header
        title={
          isEdit
            ? intl.formatMessage({
                id: 'visit-data.edit-custom-data-point',
                defaultMessage: 'Edit custom data point',
              })
            : intl.formatMessage({
                id: 'visit-data.new-custom-data-point',
                defaultMessage: 'New custom data point',
              })
        }
        description={
          isEdit
            ? intl.formatMessage({
                id: 'visit-data.data-point-drawer.edit-description',
                defaultMessage:
                  'This data point is in use. If you want to delete it, we’ll archive instead to prevent loss of data. ',
              })
            : ''
        }
        sx={{ paddingInline: 3 }}
      />
      <ComposableDrawerWithStickyFooter.Content sx={{ paddingInline: 3, paddingBlockStart: 0 }}>
        <Stack spacing={3} sx={{ marginTop: 3 }}>
          <FormInput
            name="name"
            label={intl.formatMessage({ id: 'common.name', defaultMessage: 'Name' })}
            isRequired
            onBlur={() => {
              !isEdit &&
                setValue('referenceCode', getValues('name').replace(/\s+/g, '-').toLowerCase());
            }}
            placeholder={intl.formatMessage({
              id: 'common.name',
              defaultMessage: 'Name',
            })}
          />

          {isEdit ? (
            <Stack spacing={1}>
              <Typography variant="body2" color="text.secondary">
                {intl.formatMessage({
                  id: 'common.reference-code',
                  defaultMessage: 'Reference code',
                })}
              </Typography>
              <Typography variant="body1">{selectedDataPoint?.internal_key}</Typography>
            </Stack>
          ) : (
            <FormInput
              name="referenceCode"
              label={intl.formatMessage({
                id: 'common.reference-code',
                defaultMessage: 'Reference code',
              })}
              placeholder={intl.formatMessage({
                id: 'common.reference-code',
                defaultMessage: 'Reference code',
              })}
              isRequired
              sx={{ maxWidth: 220 }}
            />
          )}

          {isEdit ? (
            <Stack spacing={1}>
              <Typography variant="body2" color="text.secondary">
                {intl.formatMessage({ id: 'common.type', defaultMessage: 'Type' })}
              </Typography>
              <Typography variant="body1">{selectedDataPoint?.type}</Typography>
            </Stack>
          ) : (
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink>
                {intl.formatMessage({ id: 'common.type', defaultMessage: 'Type' })}
                <Box component="span" sx={{ marginLeft: 0.5, color: 'error.main' }}>
                  *
                </Box>
              </InputLabel>
              <CustomTextFieldWithSeparatedLabel
                fullWidth
                size="small"
                error={!!errors?.type}
                select
                helperText={
                  !!errors?.type &&
                  intl.formatMessage({
                    id: 'common.mandatoryField',
                    defaultMessage: 'Mandatory field',
                  })
                }
                defaultValue="open_text"
                disabled={isEdit}
                sx={{ maxWidth: 220 }}
                {...register('type', { required: true })}
              >
                {types.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </CustomTextFieldWithSeparatedLabel>
            </FormControl>
          )}

          {selectedType === 'datetime' && (
            <Stack>
              <Typography variant="body2" color="text.secondary">
                <FormattedMessage id="common.format" defaultMessage="Format" />
              </Typography>
              <Typography variant="body1">YYYY-MM-DD</Typography>
            </Stack>
          )}

          {selectedType !== 'datetime' && (
            <FormInput
              name="exampleValue"
              label={intl.formatMessage({
                id: 'visit-data.data-point-drawer.example-label',
                defaultMessage: 'Example value, used in the upload template',
              })}
              sx={{ maxWidth: 220 }}
              placeholder={intl.formatMessage({
                id: 'common.example-value',
                defaultMessage: 'Example value',
              })}
            />
          )}

          {selectedType === 'custom_entity_code' && (
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink>
                {intl.formatMessage({
                  id: 'common.custom-attribute',
                  defaultMessage: 'Custom attribute',
                })}
              </InputLabel>
              <Controller
                name="externalEntityID"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextFieldWithSeparatedLabel
                    {...field}
                    fullWidth
                    size="small"
                    select
                    sx={{ maxWidth: 220 }}
                    error={!!errors?.externalEntityID}
                  >
                    {customAttributes.map((attribute) => (
                      <MenuItem key={attribute.id} value={attribute.id}>
                        {attribute.name}
                      </MenuItem>
                    ))}
                  </CustomTextFieldWithSeparatedLabel>
                )}
              />
            </FormControl>
          )}

          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked={selectedDataPoint?.mandatory} />}
              label={
                <FormattedMessage
                  id="visit-data.new-custom-data-point.make-data-point-mandatory"
                  defaultMessage="Make data point mandatory"
                />
              }
              {...register('datapointMandatory')}
            />
          </FormGroup>

          {selectedType === 'single_select' && <SingleSelectOptions />}
          <AlternativeReferenceOptions />
        </Stack>
      </ComposableDrawerWithStickyFooter.Content>
      <ComposableDrawerWithStickyFooter.Divider />
      <ComposableDrawerWithStickyFooter.Footer>
        {isEdit && (
          <Stack sx={{ width: '100%', justifyContent: 'flex-start' }}>
            <Button
              variant="text"
              sx={{ color: 'error.main', width: 140 }}
              onClick={() => setIsDeleteDialogOpen(true)}
            >
              <FormattedMessage
                id="visit-data.common.delete-data-point"
                defaultMessage="Delete data point"
              />
            </Button>
          </Stack>
        )}
        <Button
          variant="text"
          sx={{ color: 'text.primary' }}
          onClick={() => {
            onClose();
            reset();
          }}
        >
          <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          <FormattedMessage id="common.save" defaultMessage="Save" />
        </Button>
      </ComposableDrawerWithStickyFooter.Footer>
      <DeleteDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={handleDelete}
      />
    </ComposableDrawerWithStickyFooter>
  );
}
