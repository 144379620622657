import { useMutation, useQuery } from '@tanstack/react-query';

import {
  getEmailNotification,
  getEmailNotificationSurveyModeration,
  getResponseTemplates,
  getSignature,
  setEmailNotification,
  setEmailNotificationSurveyModeration,
  setSignature,
} from './fetchers';

import type {
  EmailNotificationSettingsIn_Input,
  EmailSurveyNotificationSettingsIn,
} from '../../../client';
import {
  EMAIL_NOTIFICATION,
  EMAIL_NOTIFICATION_SURVEY_MODERATION,
  RESPONSE_TEMPLATES,
  SIGNATURE,
} from '../../../constants/query-keys';

export function useEmailSettings() {
  return useQuery({
    queryKey: [EMAIL_NOTIFICATION],
    queryFn: getEmailNotification,
    refetchOnWindowFocus: false,
  });
}

export function useUpdateEmailSettings() {
  return useMutation({
    mutationFn: (payload: EmailNotificationSettingsIn_Input) => setEmailNotification(payload),
  });
}

export function useEmailSettingsForSurveyModeration() {
  return useQuery({
    queryKey: [EMAIL_NOTIFICATION_SURVEY_MODERATION],
    queryFn: getEmailNotificationSurveyModeration,
    refetchOnWindowFocus: false,
  });
}

export function useUpdateEmailSettingsForSurveyModeration() {
  return useMutation({
    mutationFn: (payload: EmailSurveyNotificationSettingsIn) =>
      setEmailNotificationSurveyModeration(payload),
  });
}

export function useGetResponseTemplates() {
  return useQuery({
    queryKey: [RESPONSE_TEMPLATES],
    queryFn: getResponseTemplates,
    refetchOnWindowFocus: false,
  });
}

export function useGetSignature() {
  return useQuery({
    queryKey: [SIGNATURE],
    queryFn: getSignature,
    refetchOnWindowFocus: false,
  });
}

export function useSetSignature() {
  return useMutation({
    mutationFn: (signature: string) => setSignature(signature),
  });
}
