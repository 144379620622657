import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Stack, Typography } from '@trustyou/ui';

import {
  APPROVER_PLACEHOLDER,
  AUTHOR_PLACEHOLDER,
  REQUESTOR_PLACEHOLDER,
} from '../../../constants';
import { useReview } from '../../../hooks';
import { getHumanDate } from '../../../utils/date';
import { isDeletionConfirmed, isDeletionPendingOrRequested } from '../../../utils/review';
import {
  ConfirmedResponseStampPair,
  DeletedStampPair,
  MarkedAsDeletedStampPair,
  MarkedAsInappropriateStampPair,
  MarkedAsRespondedStamp,
} from '../../stamps';
import { InappropriateProcessingStampPair } from '../../stamps/inappropriate-processing-stamp-pair';
import {
  DeletedChip,
  InappropriateProcessingChip,
  MarkedAsDeletedChip,
  MarkedInappropriateChip,
  RespondedChip,
} from '../../status-chips';
import { InappropriateChip } from '../../status-chips/inappropriate-chip';

export function ResponseInfo() {
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });

  const { response, deletion, moderation } = reviewRoot ?? {};

  const isResponded = Boolean(response?.date);
  const isConfirmed = Boolean(response?.produced_at);

  const isMarkedAsDeleted = isDeletionPendingOrRequested(deletion);
  const isDeleted = isDeletionConfirmed(deletion);

  const isModerationRequested = moderation?.status === 'requested';
  const isProcessingModeration = moderation?.status === 'propagating';
  const isModerated = moderation?.status === 'approved';

  return (
    <Stack spacing={1.5}>
      <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
        <Typography variant="h6" sx={{ fontSize: 'initial' }}>
          <FormattedMessage id="inbox.response.header.title" defaultMessage="Status" />
        </Typography>
        {(isResponded || isConfirmed) && <RespondedChip />}
        {isMarkedAsDeleted && <MarkedAsDeletedChip />}
        {isDeleted && <DeletedChip />}
        {isModerationRequested && <MarkedInappropriateChip />}
        {isProcessingModeration && <InappropriateProcessingChip />}
        {isModerated && <InappropriateChip />}
      </Stack>
      <Stack sx={{ flexWrap: 'wrap' }}>
        {isConfirmed ? (
          <ConfirmedResponseStampPair
            date={getHumanDate(response?.produced_at ?? '')}
            author={response?.author ?? AUTHOR_PLACEHOLDER}
          />
        ) : isResponded ? (
          <MarkedAsRespondedStamp
            date={getHumanDate(response?.date ?? '')}
            author={response?.author ?? AUTHOR_PLACEHOLDER}
            isConfirmed={isConfirmed}
          />
        ) : null}
        {isMarkedAsDeleted && (
          <MarkedAsDeletedStampPair
            date={getHumanDate(deletion?.requested_at ?? '')}
            author={deletion?.username ?? AUTHOR_PLACEHOLDER}
          />
        )}
        {isDeleted && (
          <DeletedStampPair
            date={getHumanDate(deletion?.confirmed_at ?? '')}
            author={deletion?.username ?? AUTHOR_PLACEHOLDER}
          />
        )}
        {isModerationRequested && (
          <MarkedAsInappropriateStampPair
            date={getHumanDate(moderation?.request.created_at ?? '')}
            requestor={moderation?.request.username ?? REQUESTOR_PLACEHOLDER}
          />
        )}
        {(isProcessingModeration || isModerated) && (
          <InappropriateProcessingStampPair
            date={getHumanDate(moderation?.request.created_at ?? '')}
            requestor={moderation?.request.username ?? REQUESTOR_PLACEHOLDER}
            approver={moderation?.revision?.username ?? APPROVER_PLACEHOLDER}
          />
        )}
      </Stack>
    </Stack>
  );
}
