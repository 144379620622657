import { useOrgCenter } from './useOrgCenter';

import type { DashboardFilter } from '../types';

export const useApplySecurityFilters = () => {
  const orgCenter = useOrgCenter();

  const applySecurityFilters = (
    dashboardFilters: DashboardFilter[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sisenseFrame: any
  ) => {
    if (!orgCenter) return;
    const filterValueMap: Record<string, string> = {
      organization_id: orgCenter.claims.organization_id,
      scope_id: orgCenter.claims.scope_id,
    };
    const filters = dashboardFilters
      .filter(
        (filter) =>
          filter.jaql &&
          filter.jaql.column &&
          Object.keys(filterValueMap).includes(filter.jaql.column)
      )
      .map((filter) => ({
        jaql: {
          title: filter.jaql.title,
          dim: filter.jaql.dim,
          datatype: 'text',
          filter: {
            members: [filterValueMap[filter.jaql.column as string]],
          },
        },
        panel: 'scope',
      }));
    sisenseFrame.dashboard.applyFilters(filters);
  };

  return {
    applySecurityFilters,
  };
};
