import { type SyntheticEvent, useState } from 'react';
import { type Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { SxProps, Theme } from '@mui/system';
import {
  getPaginationRequest,
  getSortRequest,
  useCombinedUsersByOrganization,
  useMembershipStore,
} from '@trustyou/shared';
import { Autocomplete, FormControl, FormHelperText, Stack, TextField } from '@trustyou/ui';
import { debounce } from 'lodash';

import { RecipientOption } from './recipient-option';
import { RenderTags } from './render-tags';

import { RECIPIENTS_PAGE_MODEL, RECIPIENTS_SORT_MODEL } from '../../constants/data-grid';
import { recipientsSelectorMessages } from '../../constants/messages';
import { FormControlLabel } from '../form-control-label/form-control-label';

export type Recipient = {
  id: string;
  email: string;
  name: string;
};

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  sx?: SxProps<Theme>;
};

export const RecipientSelector = ({ control, sx }: Props) => {
  const intl = useIntl();
  const { membership } = useMembershipStore();
  const [searchKey, setSearchKey] = useState('');
  //useUsersByOrganization
  const { data, isFetching } = useCombinedUsersByOrganization(
    getPaginationRequest(RECIPIENTS_PAGE_MODEL),
    getSortRequest(RECIPIENTS_SORT_MODEL),
    { searchKey },
    membership?.organizationId
  );
  const isError = !!control._formState.errors.recipients;

  const options = (data?.data || [])
    .filter((item) => item.status === 'active')
    .map(({ id, name, email }) => ({ id, name, email }));

  const onInputChangeDebounced = debounce((_: SyntheticEvent, value: string) => {
    setSearchKey(value);
  }, 500);

  return (
    <FormControl sx={sx}>
      <Controller
        control={control}
        name="recipients"
        render={({ field: { value, onChange } }) => (
          <Stack>
            <FormControlLabel required>
              {intl.formatMessage(recipientsSelectorMessages.recipients)}
            </FormControlLabel>
            <Autocomplete
              size="small"
              filterOptions={(options) => options}
              onInputChange={onInputChangeDebounced}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id || value === null}
              getOptionLabel={(option) => option.email}
              openOnFocus={true}
              multiple
              limitTags={1}
              onChange={(_, newValue) => {
                onChange(newValue);
              }}
              noOptionsText={intl.formatMessage(recipientsSelectorMessages.notFound)}
              clearOnBlur
              handleHomeEndKeys
              id="recipient-selector"
              options={options}
              renderOption={RecipientOption}
              renderTags={RenderTags}
              renderInput={(params) => (
                <TextField
                  placeholder={intl.formatMessage(recipientsSelectorMessages.placeholder)}
                  error={isError}
                  {...params}
                />
              )}
              loadingText={intl.formatMessage(recipientsSelectorMessages.loading)}
              loading={isFetching}
              value={value}
              defaultValue={control._defaultValues.recipients as Recipient[]}
            />
            {isError && (
              <FormHelperText error>
                {intl.formatMessage(recipientsSelectorMessages.required)}
              </FormHelperText>
            )}
          </Stack>
        )}
      />
    </FormControl>
  );
};
