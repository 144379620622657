import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import type { AccordionProps, AccordionSummaryProps } from '@mui/material';
import { faChevronDown, faPlus, faTrashCan } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { capitalizeFirstLetter, useLanguageStore } from '@trustyou/shared';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
  styled,
} from '@trustyou/ui';

import type { TabPanelProps } from '../../../../pages/tabs/tabs';
import type { SurveyQuestionnaireEditorContentOutput } from '../../../../types/survey';
import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<Icon icon={faChevronDown} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  marginLeft: theme.spacing(2),
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginLeft: theme.spacing(2),
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
}));

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`survey-editor-rules-tabpanel-${index}`}
      aria-labelledby={`survey-editor-rules-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: 3 }}>{children}</Box>}
    </Box>
  );
};

export function EditorRulesContent() {
  const { watch, setValue } = useFormContext();
  const [tab, setTab] = useState(0);
  const { locale } = useLanguageStore();
  const userSettingLang = locale.split('-')[0];
  const allContent = watch('surveyContent')?.filter(
    (content: SurveyQuestionnaireEditorContentOutput) => content.type === 'question'
  );

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Typography variant="h6">
        <FormattedMessage id="survey.common.question-rules" defaultMessage="Question rules" />
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 3 }}>
        <FormattedMessage
          id="survey.survey-editor.survey-rules.description"
          defaultMessage="Set specific rules for each question"
        />
      </Typography>
      {allContent &&
        allContent.map((content: SurveyQuestionnaireEditorContentOutput, index: number) => (
          <CustomAccordion key={content.id} style={{ marginInline: '-24px' }}>
            <CustomAccordionSummary>
              <Typography variant="subtitle1">
                {capitalizeFirstLetter(
                  content.internal_name[userSettingLang] ??
                    content.internal_name[content.default_language ?? 'en']
                )}
              </Typography>
            </CustomAccordionSummary>
            <AccordionDetails sx={{ marginInline: 3 }}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tab} onChange={handleChangeTabs} aria-label="basic tabs example">
                    <Tab
                      label={
                        <FormattedMessage id="survey.common.general" defaultMessage="General" />
                      }
                    />
                    <Tab
                      label={
                        <FormattedMessage
                          id="survey.common.trigger-question"
                          defaultMessage="Trigger question"
                        />
                      }
                    />
                    <Tab
                      label={
                        <FormattedMessage
                          id="survey.common.data-point"
                          defaultMessage="Data point"
                        />
                      }
                    />
                    <Tab
                      label={
                        <FormattedMessage id="survey.common.segment" defaultMessage="Segment" />
                      }
                    />
                  </Tabs>
                </Box>
                {/* Tab 1 */}
                <CustomTabPanel value={tab} index={0}>
                  <FormGroup sx={{ paddingInline: 3 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={content.mandatory}
                          onChange={(e) => {
                            content.mandatory = e.target.checked;
                            setValue('surveyContent', watch('surveyContent'));
                          }}
                        />
                      }
                      label={
                        <FormattedMessage
                          id="survey.survey-editor.rules.mandatory"
                          defaultMessage="This question is mandatory"
                        />
                      }
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={content.not_applicable}
                          onChange={(e) => {
                            content.not_applicable = e.target.checked;
                            setValue('surveyContent', watch('surveyContent'));
                          }}
                        />
                      }
                      label={
                        <FormattedMessage
                          id="survey.survey-editor.rules.not-applicable"
                          defaultMessage="Allow respondents to say this question doesn’t apply to them"
                        />
                      }
                    />
                  </FormGroup>
                </CustomTabPanel>
                {/* Tab 2 */}
                <CustomTabPanel value={tab} index={1}>
                  <Stack sx={{ alignItems: 'center' }}>
                    <Button variant="text" startIcon={<Icon icon={faPlus} />}>
                      <FormattedMessage
                        id="survey.common.add-trigger"
                        defaultMessage="Add trigger"
                      />
                    </Button>
                  </Stack>
                  <Stack flexDirection="row" sx={{ gap: 2, alignItems: 'flex-end' }}>
                    <FormControl variant="standard" fullWidth sx={{ maxWidth: 242 }}>
                      <InputLabel shrink>
                        <FormattedMessage
                          id="survey.survey-editor.rules.show-this-question-if.label"
                          defaultMessage="Show this question only if:"
                        />
                      </InputLabel>
                      <CustomTextFieldWithSeparatedLabel fullWidth select size="small">
                        <MenuItem key={1} value={1}>
                          Test
                        </MenuItem>
                      </CustomTextFieldWithSeparatedLabel>
                    </FormControl>

                    <CustomTextFieldWithSeparatedLabel
                      fullWidth
                      select
                      size="small"
                      sx={{ maxWidth: 112 }}
                    >
                      <MenuItem key={1} value={1}>
                        Test
                      </MenuItem>
                    </CustomTextFieldWithSeparatedLabel>
                    <CustomTextFieldWithSeparatedLabel
                      fullWidth
                      select
                      size="small"
                      sx={{ maxWidth: 242 }}
                    >
                      <MenuItem key={1} value={1}>
                        Test
                      </MenuItem>
                    </CustomTextFieldWithSeparatedLabel>
                    <IconButton>
                      <Icon icon={faTrashCan} />
                    </IconButton>
                  </Stack>
                </CustomTabPanel>
                {/* Tab 3 */}
                <CustomTabPanel value={tab} index={2}>
                  <Stack flexDirection="row" sx={{ gap: 2, alignItems: 'flex-end' }}>
                    <FormControl variant="standard" fullWidth sx={{ maxWidth: 242 }}>
                      <InputLabel shrink>
                        <FormattedMessage
                          id="survey.survey-editor.rules.show-this-question-if.label"
                          defaultMessage="Show this question only if:"
                        />
                      </InputLabel>
                      <CustomTextFieldWithSeparatedLabel fullWidth select size="small">
                        <MenuItem key={1} value={1}>
                          Test
                        </MenuItem>
                      </CustomTextFieldWithSeparatedLabel>
                    </FormControl>

                    <CustomTextFieldWithSeparatedLabel
                      fullWidth
                      select
                      size="small"
                      sx={{ maxWidth: 112 }}
                    >
                      <MenuItem key={1} value={1}>
                        Test
                      </MenuItem>
                    </CustomTextFieldWithSeparatedLabel>
                    <CustomTextFieldWithSeparatedLabel
                      fullWidth
                      select
                      size="small"
                      sx={{ maxWidth: 242 }}
                    >
                      <MenuItem key={1} value={1}>
                        Test
                      </MenuItem>
                    </CustomTextFieldWithSeparatedLabel>
                    <IconButton>
                      <Icon icon={faTrashCan} />
                    </IconButton>
                  </Stack>
                  <Button variant="text" startIcon={<Icon icon={faPlus} />}>
                    <FormattedMessage id="survey.common.more" defaultMessage="More" />
                  </Button>
                </CustomTabPanel>
                {/* Tab 4 */}
                <CustomTabPanel value={tab} index={3}>
                  <Stack flexDirection="row" sx={{ gap: 2, alignItems: 'flex-end' }}>
                    <FormControl variant="standard" fullWidth sx={{ maxWidth: 242 }}>
                      <InputLabel shrink>
                        <FormattedMessage
                          id="survey.survey-editor.rules.show-this-question-if.label"
                          defaultMessage="Show this question only if:"
                        />
                      </InputLabel>
                      <CustomTextFieldWithSeparatedLabel fullWidth select size="small">
                        <MenuItem key={1} value={1}>
                          Test
                        </MenuItem>
                      </CustomTextFieldWithSeparatedLabel>
                    </FormControl>

                    <CustomTextFieldWithSeparatedLabel
                      fullWidth
                      select
                      size="small"
                      sx={{ maxWidth: 112 }}
                    >
                      <MenuItem key={1} value={1}>
                        Test
                      </MenuItem>
                    </CustomTextFieldWithSeparatedLabel>
                    <CustomTextFieldWithSeparatedLabel
                      fullWidth
                      select
                      size="small"
                      sx={{ maxWidth: 242 }}
                    >
                      <MenuItem key={1} value={1}>
                        Test
                      </MenuItem>
                    </CustomTextFieldWithSeparatedLabel>
                    <IconButton>
                      <Icon icon={faTrashCan} />
                    </IconButton>
                  </Stack>
                  <Button variant="text" startIcon={<Icon icon={faPlus} />}>
                    <FormattedMessage id="survey.common.more" defaultMessage="More" />
                  </Button>
                </CustomTabPanel>
              </Box>
            </AccordionDetails>
          </CustomAccordion>
        ))}
    </>
  );
}
