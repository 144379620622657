import { useIntl } from 'react-intl';

import { useAttributeValues } from '@trustyou/shared';
import { MultipleSelectWithCheckboxes } from '@trustyou/ui';

import { manageDrawer } from '../../../../constants/messages/benchmarks';
import { styles } from './styles';

type Props = {
  attribute?: string;
  defaultValues?: string[];
  onChange: (values: string[]) => void;
};

export const AttributeValuesSelector = ({ attribute, defaultValues, onChange }: Props) => {
  const intl = useIntl();
  const { data: attributeValues = [], isPending } = useAttributeValues(attribute);
  return (
    <MultipleSelectWithCheckboxes
      all={{ key: 'All', label: 'All' }}
      names={attributeValues}
      fieldName="definition-attribute-values"
      defaultValue={defaultValues}
      placeholder={
        attribute && !attributeValues.length && !isPending
          ? intl.formatMessage(manageDrawer.definitionRulesSelectNoValues)
          : intl.formatMessage(manageDrawer.definitionRulesSelectPlaceholder)
      }
      variant="outlined"
      sx={styles.ruleSelect}
      disabled={!attribute || !attributeValues.length}
      onChange={onChange}
    />
  );
};
