import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { faPenNibSlash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { TRUSTYOU_SURVEY_DOMAIN_NAME } from '../../../constants';
import { useReview } from '../../../hooks';

export const NonRespondableMessage = () => {
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const isSurvey = reviewRoot?.review.domain_name === TRUSTYOU_SURVEY_DOMAIN_NAME;

  return (
    <Stack
      spacing={1.5}
      sx={{ alignItems: 'center', margin: 'auto', color: 'text.disabled', p: 3 }}
    >
      <StyledFontAwesomeIcon data-testid="non-respondable-icon" icon={faPenNibSlash} size="xl" />
      <Typography variant="caption" sx={{ textAlign: 'center', maxWidth: 370 }}>
        {isSurvey ? (
          <FormattedMessage
            id="inbox.response.non-respondable-explanation.survey"
            defaultMessage="At the moment it is not possible to respond to this review. We are working on it to make it happen"
          />
        ) : (
          <FormattedMessage
            id="inbox.response.non-respondable-explanation.review"
            defaultMessage="It is not possible to respond to this review because the source doesn't allow it."
          />
        )}
      </Typography>
    </Stack>
  );
};
