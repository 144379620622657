import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  type CustomAttributeDefinition,
  CustomAttributeType,
  commonFields,
} from '@trustyou/shared';
import { Button, ComposableDrawerWithStickyFooter, DiscardChangesModal, Stack } from '@trustyou/ui';

import { DeleteAction } from './DeleteAction';
import { DescriptionController } from './DescriptionController';
import { MandatoryController } from './MandatoryController';
import { NameController } from './NameController';
import { type Option, OptionsController } from './OptionsController/OptionsController';
import { SaveAction } from './SaveAction';
import { TypeController } from './TypeController';
import { UniqueController } from './UniqueController';

import { manageDrawer } from '../../constants/messages/customAttributes';
import { styles } from './styles';

type Props = {
  data?: CustomAttributeDefinition;
  onClose: () => void;
};

export interface CustomAttributeFormData {
  id?: string;
  name: string;
  type: string;
  description?: string;
  unique: boolean;
  mandatory: boolean;
  options: Option[];
}

export const Form = ({ data, onClose }: Props) => {
  const intl = useIntl();
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const defaultOptionsIds = (data?.default || []).map((row) => row.id);
  const savedOptions = (data?.options || []).map((option) => ({
    ...option,
    isDefault: defaultOptionsIds.includes(option.id),
  }));

  const defaultValues = {
    id: data?.id,
    name: data?.name || '',
    type: data?.type || '',
    description: data?.description,
    unique: !!data?.unique,
    mandatory: !!data?.mandatory,
    options: savedOptions,
  };
  const methods = useForm<CustomAttributeFormData>({
    mode: 'onChange',
    defaultValues,
  });

  const { formState, watch, trigger } = methods;

  useEffect(() => {
    // Currently in edit mode, it takes at lease two changes to trigger validity of the form.
    // This trick will fix it. But there could be a better solution
    if (data) trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const type = watch('type');

  const hasOptions =
    type === CustomAttributeType.SELECT || type === CustomAttributeType.MULTI_SELECT;

  const onCancel = () => {
    formState.isDirty ? setShowCancelConfirmation(true) : onClose();
  };

  return (
    <FormProvider {...methods}>
      <ComposableDrawerWithStickyFooter.Header
        title={intl.formatMessage(data ? manageDrawer.editHeader : manageDrawer.createHeader)}
        onClose={onCancel}
        sx={styles.formHeader}
      />
      <ComposableDrawerWithStickyFooter.Content sx={styles.formContent}>
        <Stack gap={3}>
          <NameController />
          <TypeController />
          <DescriptionController />
          <Stack gap={1}>
            {type === CustomAttributeType.TEXT && <UniqueController />}
            <MandatoryController />
          </Stack>
          {hasOptions && <OptionsController />}
        </Stack>
      </ComposableDrawerWithStickyFooter.Content>
      <ComposableDrawerWithStickyFooter.Footer sx={styles.formFooter}>
        {data && <DeleteAction data={data} onDelete={onClose} />}
        <Button onClick={onCancel} type="button">
          {intl.formatMessage(commonFields.cancel)}
        </Button>
        <SaveAction data={data} onSave={onClose} />
      </ComposableDrawerWithStickyFooter.Footer>
      {/* TODO: Implement something shared like alert store that we can use everywhere without importing components. */}
      <DiscardChangesModal
        open={showCancelConfirmation}
        onClose={() => setShowCancelConfirmation(false)}
        onDiscard={onClose}
      />
    </FormProvider>
  );
};
