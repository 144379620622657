import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import Hotjar from '@hotjar/browser';
import {
  AuthPendingState,
  useAcceptAllInvitations,
  useAuthorizationStore,
  useChangelingStore,
  useMembershipStore,
  useMembershipsAndInvitations,
} from '@trustyou/shared';
import { HallwayRoute, LoadingPlaceholder, SomethingWrongRoute } from '@trustyou/ui';

import { ForbiddenPage } from './ForbiddenPage';
import { InvalidMembershipPage } from './InvalidMembershipPage';
import NavigationLayer from './NavigationLayer';

import useCustomersLogout from '../../service/hooks/useCustomersLogout';
import { useInvitationStore } from '../../store';
import { getMembershipIdFromUrl } from '../../utils/misc';
import { TopBarLayout } from '../Nav/TopBarLayout/TopBarLayout';
import { SwitchMembership } from '../SwitchMembership/SwitchMembership';

/* 
  This layer is responsible for memberships and user organization.
  Fetch token, changeling and permissions are handled is lower layers
*/
const MembershipLayer = () => {
  const location = useLocation();
  const { login, isAuthenticated } = useOidc();
  const logoutHandler = useCustomersLogout();
  const { pendingState, setPendingState } = useAuthorizationStore();
  const { accessToken: keycloakAccessToken, accessTokenPayload } = useOidcAccessToken();
  const {
    membership,
    setMembership,
    setDefaultMembership,
    organizations,
    hasValidMembership,
    setOrganizations,
    getMembershipsCount,
  } = useMembershipStore();
  const { isChangeling } = useChangelingStore();
  const { isInvited, setIsInvited } = useInvitationStore();
  const { acceptAll } = useAcceptAllInvitations({ keycloakAccessToken });
  const {
    data: membershipsAndInvitations,
    isError: isMembershipError,
    isSuccess,
  } = useMembershipsAndInvitations({
    keycloakAccessToken,
    enabled: isAuthenticated && !isChangeling && !isInvited,
  });

  const [allInvitationAccepted, setAllInvitationsAccepted] = useState(false);

  const membershipIdFromUrl = getMembershipIdFromUrl();
  const hasValidUrlMembership = hasValidMembership(membershipIdFromUrl);
  const hasValidStoredMembership = hasValidMembership(membership?.id);

  useEffect(() => {
    if (isSuccess) {
      setOrganizations(membershipsAndInvitations.organizations);
    }
  }, [isSuccess, membershipsAndInvitations, setOrganizations]);

  useEffect(() => {
    (async () => {
      // alert(`inside org layer hook: ${isAuthenticated} ${pendingState} ${isCompleted}`);
      if (isAuthenticated) return;
      if (pendingState === AuthPendingState.LOGGING_IN) return;
      if (window.location.hostname === 'localhost') {
        // react-oidc lib is working with 127.0.0.1 in local
        window.location.replace(`${process.env.PUBLIC_URL}${location.pathname}${location.search}`);
        return;
      }
      const loginPath =
        pendingState === AuthPendingState.LOGGING_OUT
          ? ''
          : `${location.pathname}${location.search}`;
      setPendingState(AuthPendingState.LOGGING_IN);
      await login(`${process.env.PUBLIC_URL}${loginPath}`).then(() => {
        setPendingState(AuthPendingState.IDLE);
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, location.pathname, location.search]);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (!membershipsAndInvitations) return;
    const { invitations } = membershipsAndInvitations;
    if (invitations.length === 0) return;
    if (allInvitationAccepted) return;
    acceptAll(invitations, () => setAllInvitationsAccepted(true));
    // since acceptAll is not a mutation, it recreated each time for each rerender, so the fastest
    // solution is just to keep it  outside the deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allInvitationAccepted, isAuthenticated, membershipsAndInvitations]);

  useEffect(() => {
    // Update membership store with valid membership id in the route
    if (hasValidUrlMembership && membershipIdFromUrl && membership?.id !== membershipIdFromUrl) {
      setMembership({ id: membershipIdFromUrl });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipIdFromUrl, hasValidUrlMembership]);

  useEffect(() => {
    if (!accessTokenPayload) return;

    const siteId = Number(process.env.REACT_APP_HOTJAR_ID);
    const hotjarVersion = 6; // https://github.com/hotjar/hotjar-js

    const userId = accessTokenPayload.sub;
    const userEmail = accessTokenPayload.email;
    const organizationId = membership?.organizationId;
    const subscriptionId = membership?.subscriptionId;

    // If the user is an employee, we don't want to track them
    const employeeEmailDomains = ['@trustyou.net', '@ext.trustyou.net'];
    const isNotEmployee = employeeEmailDomains.some((domain) => !userEmail?.endsWith(domain));

    // For now, we only want to track beta testers
    // https://trustyou.slack.com/archives/C07HKT28MV2/p1726676202371459?thread_ts=1726672430.642169&cid=C07HKT28MV2
    const betaTesterEmailDomains = [
      '@sofitel.com',
      '@accor.com',
      '@pullmanshanghaicentral.com',
      '@fairmont.com',
      '@raffles.com',
      '@movenpick.com',
      '@adagio-city.com',
      '@consulting-for.accor.com',
    ];
    const isBetaTester = betaTesterEmailDomains.some((domain) => userEmail?.endsWith(domain));

    if (siteId && isBetaTester) {
      Hotjar.init(siteId, hotjarVersion);
      Hotjar.identify(userId, {
        userEmail: userEmail ?? '',
        organization_id: organizationId ?? '',
        subscription_id: subscriptionId ?? '',
      });
    }
  }, [accessTokenPayload, membership]);

  if (pendingState === AuthPendingState.LOGGING_OUT) {
    return <NavigationLayer />;
  }

  // changeling, invitation and regular flow should be blocked while not  authenticated
  if (!isAuthenticated) return null;

  // all organization and organization list setup are already made in changeling hooks
  if (isChangeling) {
    if (membershipIdFromUrl && !hasValidUrlMembership) {
      return <ForbiddenPage />;
    }
    return <NavigationLayer />;
  }

  if (membershipIdFromUrl && isInvited) {
    // wait for invitation accepted
    return <LoadingPlaceholder />;
  }

  if (isInvited && !membershipIdFromUrl) {
    // invited flag was not cleaned properly. Set it to false cause refresh and auto-accept invitation
    setIsInvited(false);
    return <LoadingPlaceholder />;
  }

  if (isMembershipError)
    return (
      <TopBarLayout>
        <SomethingWrongRoute />
      </TopBarLayout>
    );

  if (!membershipsAndInvitations || !organizations) return null;

  const { invitations } = membershipsAndInvitations;

  if (invitations.length > 0 && allInvitationAccepted) {
    console.error(
      'Invitations array is not empty after accept all invitations. Try to render regular flow'
    );
  }

  if (invitations.length > 0 && !allInvitationAccepted) {
    // wait for all invitations to be accepted
    return <LoadingPlaceholder />;
  }

  if (invitations.length === 0 && allInvitationAccepted) {
    console.info('all invitations are successfully accepted');
    setAllInvitationsAccepted(false);
  }

  if (membershipIdFromUrl) {
    return hasValidUrlMembership ? <NavigationLayer /> : <InvalidMembershipPage />;
  }

  if (hasValidStoredMembership) return <NavigationLayer />;

  if (getMembershipsCount() === 0) {
    if (membership) setMembership(undefined);
    return (
      <TopBarLayout>
        <HallwayRoute onLogout={logoutHandler} email={accessTokenPayload.email} />
      </TopBarLayout>
    );
  }

  if (getMembershipsCount() === 1) {
    setDefaultMembership();
    return <NavigationLayer />;
  }

  return (
    <TopBarLayout>
      <SwitchMembership />
    </TopBarLayout>
  );
};

export default MembershipLayer;
