import { defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Chip, PopperMenu } from '@trustyou/ui';

import { AUTHOR_PLACEHOLDER } from '../../constants';
import { useReview } from '../../hooks';
import { getHumanDate } from '../../utils/date';
import { StatusList, type StatusListItem } from './status-list';

const messages = defineMessages({
  responded: {
    id: 'inbox.response.status.chip.responded',
    defaultMessage: 'Responded',
  },
  markedAsResponded: {
    id: 'inbox.response.status.card.marked-as-responded',
    defaultMessage: 'Marked as responded',
  },
  respondedBy: {
    id: 'inbox.response.status.card.responded-by',
    defaultMessage: 'Responded by',
  },
  responseConfirmed: {
    id: 'inbox.response.status.card.confirmed',
    defaultMessage: 'Response confirmed',
  },
});

export const RespondedChip = () => {
  const intl = useIntl();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const { response } = reviewRoot ?? {};

  const isResponded = Boolean(response?.date);
  const isConfirmed = Boolean(response?.produced_at);

  const markedAsRespondedItem = {
    concept: intl.formatMessage(messages.markedAsResponded),
    content: getHumanDate(response?.date ?? ''),
  };
  const respondedByItem = {
    concept: intl.formatMessage(messages.respondedBy),
    content: response?.author ?? AUTHOR_PLACEHOLDER,
  };
  const responseConfirmedItem = {
    concept: intl.formatMessage(messages.responseConfirmed),
    content: getHumanDate(response?.produced_at ?? ''),
  };

  const statusListItems: StatusListItem[] = [];
  statusListItems.push(markedAsRespondedItem);
  if (isResponded) {
    statusListItems.push(respondedByItem);
  }
  if (isConfirmed) {
    statusListItems.push(responseConfirmedItem);
  }

  return (
    <PopperMenu
      disablePortal={false}
      placement="bottom"
      Trigger={({ onClick }) => (
        <Chip
          label={intl.formatMessage(messages.responded)}
          size="small"
          variant="pastelSuccess"
          onClick={onClick}
        />
      )}
      Content={() => <StatusList items={statusListItems} />}
    />
  );
};
