import { $assApi, INBOX_BASE_PATH } from '@trustyou/shared';

import type {
  EmailNotificationSettingsIn_Input,
  EmailNotificationSettingsIn_Output,
  EmailSurveyNotificationSettingsIn,
} from '../../../client';
import type { GetResponseTemplatesResponse } from '../../../types';

export async function getEmailNotification() {
  const { data } = await $assApi.get<EmailNotificationSettingsIn_Input[]>(
    `${INBOX_BASE_PATH}/user-settings/get-email-notification`
  );
  return data;
}

export async function setEmailNotification(payload: EmailNotificationSettingsIn_Input) {
  const { data } = await $assApi.put<EmailNotificationSettingsIn_Output>(
    `${INBOX_BASE_PATH}/user-settings/set-email-notification`,
    payload
  );
  return data;
}

export async function getEmailNotificationSurveyModeration() {
  const { data } = await $assApi.get<EmailSurveyNotificationSettingsIn>(
    `${INBOX_BASE_PATH}/user-settings/get-email-notification/survey-moderation`
  );
  return data;
}

export async function setEmailNotificationSurveyModeration(
  payload: EmailSurveyNotificationSettingsIn
) {
  const { data } = await $assApi.put<EmailNotificationSettingsIn_Output>(
    `${INBOX_BASE_PATH}/user-settings/set-email-notification/survey-moderation`,
    payload
  );
  return data;
}

export async function getResponseTemplates() {
  const { data } = await $assApi.get<GetResponseTemplatesResponse>(
    `${INBOX_BASE_PATH}/user-settings/get-response-templates`
  );
  return data;
}

export async function setSignature(signature: string) {
  const { data } = await $assApi.put<string>(
    `${INBOX_BASE_PATH}/user-settings/set-signature`,
    signature
  );
  return data;
}

export async function getSignature() {
  const { data } = await $assApi.get<string | null>(
    `${INBOX_BASE_PATH}/user-settings/get-signature`
  );
  return data;
}
