import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Stack } from '@trustyou/ui';

import { useReview } from '../../../hooks';
import { useStore } from '../../../store/store';
import type { ResponseFormSchema } from '../../../types';
import { DirectResponseButton } from './submit-buttons/direct-response-button';
import { GoToSourceButton } from './submit-buttons/go-to-source-button';
import { MarkRespondedButton } from './submit-buttons/mark-responded-button';
import { PrivateSurveyRespondButton } from './submit-buttons/private-survey-respond-button';
import { PublicSurveyRespondButton } from './submit-buttons/public-survey-respond-button';

export type SubmitButtonProps = {
  onSubmit: () => void;
  isDisabled?: boolean;
};

export const SubmitRegion = ({ onSubmit }: SubmitButtonProps) => {
  const acceptsDirectResponse = useStore.use.acceptsDirectResponse();
  const isDisabled = useStore.use.isSubmitDisabled();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const { getValues } = useFormContext<ResponseFormSchema>();

  const isPublicSurvey = reviewRoot?.survey?.privacy_level === 'public';
  const isPrivateSurvey = reviewRoot?.survey?.privacy_level === 'private';

  if (isPublicSurvey) {
    return <PublicSurveyRespondButton onSubmit={onSubmit} isDisabled={isDisabled} />;
  }

  if (isPrivateSurvey) {
    return <PrivateSurveyRespondButton onSubmit={onSubmit} isDisabled={isDisabled} />;
  }

  if (acceptsDirectResponse) {
    return <DirectResponseButton onSubmit={onSubmit} isDisabled={isDisabled} />;
  }

  return (
    <Stack direction="row" spacing={2}>
      <GoToSourceButton response={getValues('response')} isDisabled={isDisabled} />
      <MarkRespondedButton onSubmit={onSubmit} />
    </Stack>
  );
};
