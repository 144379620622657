import type { IStyles } from '@trustyou/shared';

export const styles: IStyles = {
  rulesStack: { alignItems: 'center', gap: 2, flexDirection: 'row' },
  addRuleButton: { gap: 1, width: 'fit-content' },
  ruleSelect: {
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: '50%',
    overflow: 'hidden',
    '& .MuiSelect-select': {
      paddingX: 1.5,
      paddingY: 1,
    },
  },
};
