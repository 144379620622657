import {
  type GridRowSelectionModel,
  type GridSlotsComponentsProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import { Box } from '@trustyou/ui';

import { CustomBulkActions } from './custom-bulk-actions';
import { CustomGridToolbarQuickFilter } from './custom-grid-toolbar-quick-filter';
import { InfoIcon } from './info-icon';
import { RefreshButton } from './refresh-button';

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    selectedRows: GridRowSelectionModel;
    resetSelectedRows: () => void;
  }
}

export function CustomToolbar({
  selectedRows,
  resetSelectedRows,
}: NonNullable<GridSlotsComponentsProps['toolbar']>) {
  return (
    <GridToolbarContainer sx={{ paddingInline: 2, paddingBlock: 1 }}>
      {selectedRows?.length && selectedRows?.length > 0 ? (
        <CustomBulkActions
          selectedRows={selectedRows}
          resetSelectedRows={() => resetSelectedRows}
        />
      ) : (
        <>
          <RefreshButton />
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
        </>
      )}
      <Box component="span" flexGrow={1} />
      <CustomGridToolbarQuickFilter />
      <InfoIcon />
    </GridToolbarContainer>
  );
}
