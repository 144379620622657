import { FormattedMessage } from 'react-intl';

import { ENTITY_GLOBAL_ATTRIBUTES, useAttributesForRules } from '@trustyou/shared';
import { MenuItem, Select, Typography } from '@trustyou/ui';
import { capitalize } from 'lodash';

import { manageDrawer } from '../../../../constants/messages/benchmarks';
import { styles } from './styles';

type Props = {
  value?: string;
  globalAttributes?: boolean;
  onChange: (string: string) => void;
};

const HIDDEN_ATTRIBUTES = ['type', 'address', 'name'];

export const AttributeSelector = ({ value, globalAttributes, onChange }: Props) => {
  const { data: allAttributes = [] } = useAttributesForRules(!globalAttributes);
  let attributes = globalAttributes ? ENTITY_GLOBAL_ATTRIBUTES : allAttributes;
  attributes = attributes.filter((row) => !HIDDEN_ATTRIBUTES.includes(row)).sort();
  return (
    <Select
      value={(attributes.length && value) || ''}
      onChange={(event) => onChange(event.target.value)}
      displayEmpty
      renderValue={(value) =>
        capitalize(value) || (
          <Typography color="gray">
            <FormattedMessage {...manageDrawer.definitionRulesSelectPlaceholder} />
          </Typography>
        )
      }
      sx={styles.ruleSelect}
    >
      {attributes.map((attribute) => (
        <MenuItem key={attribute} value={attribute}>
          {capitalize(attribute)}
        </MenuItem>
      ))}
    </Select>
  );
};
