import { FormattedMessage } from 'react-intl';

import type { ButtonProps } from '@mui/material';
import { Box, Button } from '@trustyou/ui';

export type ActiveFiltersProps = {
  count?: number;
  isDisabled?: boolean;
  onClick?: ButtonProps['onClick'];
  onClear?: ButtonProps['onClick'];
};

export function FiltersButton({
  count = 0,
  isDisabled = false,
  onClick,
  onClear,
}: ActiveFiltersProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Button
        type="button"
        disabled={isDisabled}
        onClick={onClick}
        color="primary"
        variant="contained"
        data-gtm-id="inbox_filters_drawer_icon_button"
      >
        <FormattedMessage id="inbox.filters" defaultMessage="Filters" /> {count > 0 && `(${count})`}
      </Button>
      {count > 0 && (
        <Button
          type="button"
          onClick={onClear}
          color="secondary"
          data-gtm-id="inbox_filters_clear_button"
          size="small"
          sx={{ marginTop: 0.5 }}
        >
          <FormattedMessage
            id="inbox.filters.clear"
            defaultMessage="{activeFiltersCount, plural,
          one {Clear filter}
          other {Clear filters}
        }"
            values={{ activeFiltersCount: count }}
          />
        </Button>
      )}
    </Box>
  );
}
