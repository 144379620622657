import { FormattedMessage } from 'react-intl';

import { faPlus, faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { BenchmarkDefinitionRule } from '@trustyou/shared';
import {
  Button,
  FormFieldLabel,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  Stack,
  Typography,
} from '@trustyou/ui';

import { AttributeSelector } from './AttributeSelector';
import { AttributeValuesSelector } from './AttributeValuesSelector';

import { manageDrawer } from '../../../../constants/messages/benchmarks';
import { styles } from './styles';

type Props = {
  rules: BenchmarkDefinitionRule[];
  globalAttributes?: boolean;
  onChange: (rules: BenchmarkDefinitionRule[]) => void;
};

export const DEFAULT_DEFINITION_RULE = {
  values: [],
};

const MAX_FILTERS_COUNT = 10;

export const DefinitionRuleSelector = ({ rules, globalAttributes, onChange }: Props) => {
  const addRule = () => {
    onChange([...rules, DEFAULT_DEFINITION_RULE]);
  };

  const deleteRule = (id: number) => {
    onChange(rules.filter((_, index) => index !== id));
  };

  const handleAttributeValuesChange = (id: number, values: string[]) => {
    onChange(rules.map((rule, index) => (index === id ? { ...rule, values } : rule)));
  };

  const handleAttributeChange = (id: number, attribute: string) => {
    onChange(rules.map((rule, index) => (index === id ? { attribute, values: [] } : rule)));
  };

  return (
    <Stack>
      {rules.length > 0 && (
        <>
          <FormFieldLabel>
            <FormattedMessage {...manageDrawer.definitionRulesWhere} />
          </FormFieldLabel>
          <Stack gap={1} marginBottom={2}>
            {rules.map((rule, index) => (
              <Stack key={`definition-rule-${index}`} sx={styles.rulesStack}>
                <AttributeSelector
                  value={rule.attribute}
                  globalAttributes={globalAttributes}
                  onChange={(value) => handleAttributeChange(index, value)}
                />
                <Typography variant="body1">
                  <FormattedMessage {...manageDrawer.definitionRulesIs} />
                </Typography>
                <AttributeValuesSelector
                  key={rule.attribute}
                  attribute={rule.attribute}
                  defaultValues={rule.values}
                  onChange={(values) => handleAttributeValuesChange(index, values)}
                />
                <IconButton onClick={() => deleteRule(index)}>
                  <Icon size="xs" icon={faTrash} />
                </IconButton>
              </Stack>
            ))}
          </Stack>
        </>
      )}
      <Button
        sx={styles.addRuleButton}
        onClick={addRule}
        disabled={rules.length >= MAX_FILTERS_COUNT}
      >
        <Icon icon={faPlus} />
        <FormattedMessage {...manageDrawer.definitionRulesAdd} />
      </Button>
    </Stack>
  );
};
